import { IUser, IUsersFilter } from '../components/Users/interface';
import { get, post, patch } from './apiService';
import { getUser } from './localStorage';

export const getUserList = async function (filter?: IUsersFilter) {
  if (!!getUser().bank) return;
  return await get('users', filter);
};
export const createUser = async function (user: IUser) {
  if (!!getUser().bank) return;
  return await post('users', user);
};
export const updateUser = async function (user: IUser) {
  if (!!getUser().bank) return;
  return await patch('users/' + user.id, user);
};
const userService = {
  getUserList,
  createUser,
  updateUser,
};
export default userService;
