import React from 'react';
import { useAppSelector } from '../hooks';
import { getActive } from '../components/Menu/topMenuSlice';
import { menuItems } from '../../config/constants';
import { UsersListPage } from '../components/Users/list';
import { BanksListPage } from '../components/Banks/list';
import { TopMenu } from '../components/Menu/topMenu';
import { Segment } from 'semantic-ui-react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FoldersListPage } from '../components/Folders/list';

export const AdminComponent = () => {
  const menu = useAppSelector(getActive);
  let Component: any = null;
  switch (menu) {
    case menuItems.folders:
      Component = FoldersListPage;
      break;
    case menuItems.users:
      Component = UsersListPage;
      break;
    case menuItems.banks:
      Component = BanksListPage;
      break;
  }
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <TopMenu />
      <Segment className="app-body" id="app-body" basic raised stacked>
        {Component ? <Component /> : null}
      </Segment>
    </React.Fragment>
  );
};
