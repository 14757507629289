import React from 'react';
import { logout } from '../Users/userSlice';
import { Icon, Image, Menu } from 'semantic-ui-react';
import { menuItems } from '../../../config/constants';
import { setActive, getActive } from './topMenuSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getUser } from '../../services/localStorage';

export const TopMenu = () => {
  const dispatch = useAppDispatch();
  const disconnect = () => logout(true);

  const activeItem = useAppSelector(getActive);
  const setActiveItem = (name: string) => dispatch(setActive(name));

  const user = getUser();
  const IS_ADMIN = user.bank === '';
  return (
    <Menu pointing secondary style={{ margin: 0 }}>
      <Image src="/static/logo-carre.png" size="mini" avatar spaced />
      {IS_ADMIN ? (
        <React.Fragment>
          <Menu.Item
            name={menuItems.folders}
            active={activeItem === menuItems.folders}
            onClick={(e: any, { name }: any) => setActiveItem(name)}
          >
            <Icon name="folder open outline" />
            {menuItems.folders}
          </Menu.Item>
          <Menu.Item
            name={menuItems.users}
            active={activeItem === menuItems.users}
            onClick={(e: any, { name }: any) => setActiveItem(name)}
          >
            <Icon name="users" />
            {menuItems.users}
          </Menu.Item>
        </React.Fragment>
      ) : (
        <div className="egg-title">
          <a href="/">étude généalogique Guénifey</a>
        </div>
      )}

      <Menu.Menu position="right">
        <Menu.Item name="user" disabled title={user.bank}>
          <Icon name="user" />
          {user.name}
        </Menu.Item>

        <Menu.Item
          name="Se déconnecter"
          active={activeItem === 'Se déconnecter'}
          onClick={disconnect}
        >
          <Icon name="sign-out" />
          Se déconnecter
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};
