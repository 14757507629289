import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  fetchFolders,
  selectFolderLists,
  getFilter,
  setFilterFolders,
  getFilteredFolders,
} from './folderSlice';
import {
  Table,
  Grid,
  Button,
  Icon,
  Label,
  Form,
  Message,
  Modal,
  Accordion,
  Popup,
  ButtonGroup,
} from 'semantic-ui-react';
import { getActive } from '../Menu/topMenuSlice';
import { menuItems } from '../../../config/constants';
import moment from '../../../helpers/moment';
import {
  IFolder,
  IFoldersFilter,
  FolderStatus,
  FolderType,
  types,
} from './interface';
import { Folder } from './folder';
import { orderBy } from 'lodash';
import './list.css';
import { FormPage } from '../Form';
import { isNew } from '../Common/utils';
import { getNew } from './helpers';
import { getUser } from '../../services/localStorage';
import { FolderPie } from './folderPie';

const user = getUser();
const IS_ADMIN = user.bank === '';
export const FoldersListPage = (props: any) => {
  const dispatch = useAppDispatch();

  const folders = useAppSelector(selectFolderLists);
  const initialFilter: IFoldersFilter | undefined = useAppSelector(getFilter);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [sort, setSort] = useState({
    column: 'createdAt',
    direction: 'descending',
  } as any);
  const [filterInput, setFilterInput] = useState({
    deceased: '',
    reference: '',
    code: '',
  });
  const [newFolderOpened, showNewFolder] = useState(false);
  const [dashboardOpened, showDashboardOpened] = useState(true);
  const menu = useAppSelector(getActive);

  const filter = useAppSelector(getFilter);
  let data: any = [
    ...useAppSelector((state) => getFilteredFolders(state, false)),
  ];

  useEffect(() => {
    if (!folders.folderList?.total) dispatch(fetchFolders(initialFilter));
  }, [dispatch]);

  const toggleRow = (folder: IFolder, oneAtOnce: boolean = true) => {
    if (isExpanded(folder))
      setExpandedRows(
        oneAtOnce ? [] : [...expandedRows.filter((o) => o !== folder.id)],
      );
    else {
      setExpandedRows(oneAtOnce ? [folder.id] : [...expandedRows, folder.id]);
      //   scrollTableTo(folder.id);
    }
  };

  const isExpanded = (folder: IFolder) => {
    return expandedRows.includes(folder.id);
  };
  const renderCaret = (folder: IFolder) => {
    if (isExpanded(folder)) {
      return <Icon name="caret down" className="caret-color" />;
    } else {
      return <Icon name="caret right" className="caret-color" />;
    }
  };

  const selectTypes = [
    { value: FolderType.BQ, text: types[FolderType.BQ] },
    { value: FolderType.CAV, text: types[FolderType.CAV] },
  ];

  const renderRow = (_folder: IFolder) => {
    const folder = { ..._folder };
    const expanded = data.length === 1 ? true : isExpanded(folder);

    const code = folder.status as keyof typeof FolderStatus;
    const statusIcon: any =
      FolderStatus[code]?.icon || 'question circle outline';
    const statusColor: any = FolderStatus[code]?.color || 'black';
    const haveNew = getNew(folder);
    const lastUpdate = folder.updatedAt;
    const itemRows = [
      <Table.Row
        key={folder.id}
        id={'main-row-folder-' + folder.id}
        className={(expanded ? 'expanded-row' : '') + ' folder-row'}
      >
        <Table.Cell>
          <Label
            ribbon={expanded}
            as={'a'}
            className={
              'deceased-name-link ' + (isNew(folder.updatedAt) ? 'is-new' : '')
            }
            onClick={() => toggleRow(folder)}
          >
            {renderCaret(folder)}
            {folder.lastName} {folder.firstName}
            <br />
            <label className="deceased-dates">
              <Icon name="calendar alternate outline" />
              {moment(folder.birthdate).isValid() &&
                'N:' + moment(folder.birthdate).format('DD/MM/YYYY')}
              {'   '}
              <Icon name="calendar alternate outline" />
              {'D:' + moment(folder.deathdate).format('DD/MM/YYYY')}
            </label>
          </Label>
        </Table.Cell>

        <Table.Cell>
          {types[folder.type || ''] || types[FolderType.BQ]}
        </Table.Cell>
        <Table.Cell>
          <Label basic className="folder-state-label">
            {folder.status ? (
              <Icon name={statusIcon} style={{ color: statusColor }} />
            ) : (
              <Icon name="star outline" />
            )}
            {FolderStatus[folder.status as keyof typeof FolderStatus]?.name.map(
              (line, i) => (
                <span key={i} className={'status-label-line line-' + (i + 1)}>
                  {line}
                </span>
              ),
            ) || 'Nouveau'}
          </Label>
        </Table.Cell>

        <Table.Cell textAlign="center">
          {!!folder.code && <div className="bold">{folder.code}</div>}
          <Label.Group>
            {!!(folder.messages || []).length && (
              <Label basic className={haveNew.message ? 'is-new' : ''}>
                {(folder.messages || []).length} <Icon name="talk" />
              </Label>
            )}
            {!!(folder.messages || []).length && (
              <Label basic className={haveNew.document ? 'is-new' : ''}>
                {(folder.messages || []).length} <Icon name="file pdf" />
              </Label>
            )}
            {!!(folder.heirs || []).length && (
              <Label basic className={haveNew.heir ? 'is-new' : ''}>
                {(folder.heirs || []).length} <Icon name="group" />
              </Label>
            )}
          </Label.Group>
        </Table.Cell>
        <Table.Cell>{folder.reference}</Table.Cell>

        {banques.length > 1 && <Table.Cell>{folder.user?.bank}</Table.Cell>}
        <Table.Cell textAlign="center">
          <Icon name="calendar alternate outline" />
          {moment(folder.createdAt).format('DD/MM/YYYY')}
          {folder.user ? (
            <div>
              Par {folder.user.firstName} {folder.user.lastName}
            </div>
          ) : null}
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Popup
            content={
              !!lastUpdate
                ? 'Le ' + moment(lastUpdate).format('DD/MM/YYYY')
                : '-'
            }
            position="top center"
            trigger={
              <span>{!!lastUpdate ? moment(lastUpdate).fromNow() : '-'}</span>
            }
          />
        </Table.Cell>
      </Table.Row>,
    ];

    if (expanded) {
      itemRows.push(
        <Table.Row
          key={'row-expanded-' + folder.id}
          className={'expanded-row-details '}
          id={'details-row-folder-' + folder.id}
        >
          <Table.Cell colSpan="8">
            <Folder folder={folder} />
          </Table.Cell>
        </Table.Row>,
      );
    }

    return itemRows;
  };
  if (menu !== menuItems.folders) return null;

  const search = (field: string, value?: string) => {
    dispatch(setFilterFolders({ [field]: value }));
  };
  let banques: any =
    folders.folderList?.data.map((folder: IFolder) => ({
      key: folder.user?.bank,
      text: folder.user?.bank,
      value: folder.user?.bank,
    })) || [];
  banques = banques.filter(
    (item: any, index: number) =>
      !!item.key &&
      !banques.some((b: any, i2: number) => b.key === item.key && i2 > index),
  );

  const sortBy = (column: string) => {
    setSort({
      column,
      direction: sort.direction === 'ascending' ? 'descending' : 'ascending',
    });
  };
  let headers = [
    { label: 'Défunt', field: 'lastName', sortable: true },
    { label: 'Type', field: 'type', sortable: true },
    { label: 'Statut', field: 'status', sortable: true },
    { label: 'Dossier', field: 'code', sortable: true, center: true },
    { label: 'Référence', field: 'reference', sortable: true },
    { label: 'Client', field: 'user.bank', sortable: true },
    { label: 'Créé le', field: 'createdAt', sortable: true, center: true },
    {
      label: 'Mis à jour le',
      field: 'updatedAt',
      sortable: true,
      center: true,
    },
  ];
  if (banques.length === 1) {
    headers = headers.filter((col) => !['user.bank'].includes(col.field));
  }
  const newElements = {
    document: 0,
    folder: 0,
    note: 0,
  };
  data = data.map((folder: IFolder) => {
    if (isNew(folder.updatedAt)) newElements.folder++;
    (folder.documents || []).forEach((document) => {
      if (isNew(document.createdAt)) newElements.document++;
    });
    (folder.messages || []).forEach((note) => {
      if (isNew(note.createdAt)) newElements.note++;
    });

    return folder;
  });

  return (
    <div className="folders-list-container" id="folders-list-container">
      <Modal open={newFolderOpened} onClose={() => showNewFolder(false)}>
        <Modal.Header>Nouveau dossier</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <FormPage />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div
        className="folders-dashboard-container"
        id="folders-dashboard-container"
      >
        <Accordion fluid styled as={Form.Field}>
          <Accordion.Title
            active={dashboardOpened}
            onClick={() => {
              showDashboardOpened(!dashboardOpened);
            }}
          >
            <Icon
              className={'icon caret ' + (dashboardOpened ? 'down' : 'right')}
            />{' '}
            {user.bank}
          </Accordion.Title>
          <Accordion.Content active={dashboardOpened}>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column width={4}>
                  <Message className="filter-container" floating>
                    <Message.Header>
                      <Icon name="search"></Icon> Recherche
                    </Message.Header>
                    <Message.Content>
                      <Form>
                        <Form.Input
                          icon={
                            <Icon
                              name="search"
                              inverted
                              circular
                              link
                              onClick={() =>
                                search('deceased', filterInput.deceased)
                              }
                            />
                          }
                          value={filterInput.deceased}
                          placeholder="Défunt"
                          fluid
                          onKeyPress={(event: React.KeyboardEvent) => {
                            if (event.key === 'Enter')
                              search('deceased', filterInput.deceased);
                          }}
                          onChange={(event, { value }) => {
                            setFilterInput({ ...filterInput, deceased: value });
                          }}
                        />
                        <Form.Input
                          icon={
                            <Icon
                              name="search"
                              inverted
                              circular
                              link
                              onClick={() =>
                                search('reference', filterInput.reference)
                              }
                            />
                          }
                          placeholder="Réf. client"
                          fluid
                          onKeyPress={(event: React.KeyboardEvent) => {
                            if (event.key === 'Enter')
                              search('reference', filterInput.reference);
                          }}
                          onChange={(event, { value }) => {
                            setFilterInput({
                              ...filterInput,
                              reference: value,
                            });
                          }}
                        />

                        <Form.Input
                          icon={
                            <Icon
                              name="search"
                              inverted
                              circular
                              link
                              onClick={() => search('code', filterInput.code)}
                            />
                          }
                          placeholder="Réf. Guénifey"
                          fluid
                          onKeyPress={(event: React.KeyboardEvent) => {
                            if (event.key === 'Enter')
                              search('code', filterInput.code);
                          }}
                          onChange={(event, { value }) => {
                            setFilterInput({
                              ...filterInput,
                              code: value,
                            });
                          }}
                        />

                        {banques.length > 1 && (
                          <Form.Dropdown
                            placeholder="Client"
                            fluid
                            selection
                            search
                            options={[
                              {
                                key: 'all',
                                text: 'Tous',
                                value: '',
                              },
                              ...banques,
                            ]}
                            onChange={(e, { value }) => {
                              dispatch(setFilterFolders({ bank: value }));
                            }}
                          />
                        )}

                        <Form.Dropdown
                          placeholder="Type"
                          fluid
                          selection
                          search
                          options={[
                            {
                              key: 'all',
                              text: 'Tous',
                              value: '',
                            },
                            ...selectTypes,
                          ]}
                          value={filter?.type || ''}
                          onChange={(e, { value }) => {
                            dispatch(setFilterFolders({ type: value }));
                          }}
                        />
                      </Form>
                    </Message.Content>
                  </Message>
                </Grid.Column>
                <FolderPie type={FolderType.BQ} />
                <FolderPie type={FolderType.CAV} />
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Grid.Row>
                    <Message
                      className="filter-container"
                      floating
                      icon
                      attached="bottom"
                    >
                      <Icon name="info circle" size="tiny" />
                      <Message.Header className="last-connection-header">
                        Depuis la dernière connexion
                        <Message.Content className="last-connection-date">
                          <Icon name="calendar outline" />
                          Le{' '}
                          {moment(user.lastConnection).format(
                            'DD/MM/YYYY à HH:mm',
                          )}
                        </Message.Content>
                      </Message.Header>

                      <Message.Content>
                        <ButtonGroup
                          widths="5"
                          className="last-connection-actions"
                        >
                          <Button
                            as="div"
                            labelPosition="right"
                            onClick={() => {
                              dispatch(
                                setFilterFolders({
                                  newFolder: !filter?.newFolder,
                                }),
                              );
                            }}
                            disabled={!!!newElements.folder}
                          >
                            <Button
                              basic={!filter?.newFolder}
                              color="blue"
                              fluid
                              active={filter?.newFolder}
                            >
                              <Icon name="wpforms" />
                              Dossiers mis à jour
                            </Button>
                            <Label as="a" basic color="blue" pointing="left">
                              {newElements.folder}
                            </Label>
                          </Button>
                          <Button
                            as="div"
                            labelPosition="right"
                            onClick={() => {
                              dispatch(
                                setFilterFolders({
                                  newDoc: !filter?.newDoc,
                                }),
                              );
                            }}
                            disabled={!!!newElements.document}
                          >
                            <Button
                              className={
                                newElements.document ? 'is-new' : 'basic'
                              }
                              fluid
                              active={filter?.newDoc}
                            >
                              <Icon name="file pdf" />
                              Nouveaux documents
                            </Button>
                            <Label
                              as="a"
                              basic
                              className={
                                newElements.document ? 'is-new' : 'basic'
                              }
                              pointing="left"
                            >
                              {newElements.document}
                            </Label>
                          </Button>

                          <Button
                            as="div"
                            labelPosition="right"
                            fluid
                            onClick={() => {
                              dispatch(
                                setFilterFolders({
                                  newNote: !filter?.newNote,
                                }),
                              );
                            }}
                            disabled={!!!newElements.note}
                          >
                            <Button
                              className={newElements.note ? 'is-new' : 'basic'}
                              fluid
                              active={filter?.newNote}
                            >
                              <Icon name="wechat" />
                              Nouveaux Messages
                            </Button>
                            <Label
                              as="a"
                              basic
                              className={newElements.note ? 'is-new' : 'basic'}
                              pointing="left"
                            >
                              {newElements.note}
                            </Label>
                          </Button>
                        </ButtonGroup>
                      </Message.Content>
                    </Message>
                  </Grid.Row>
                  {IS_ADMIN ? null : (
                    <Grid.Row>
                      <Message className="second-filter-container" floating>
                        <Message.Header> </Message.Header>
                        <Message.Content>
                          <Form>
                            <Form.Field>
                              <Button
                                fluid
                                primary
                                onClick={() => showNewFolder(true)}
                              >
                                <Icon name="add" /> Nouveau dossier
                              </Button>
                            </Form.Field>
                            {/* <Form.Field>
                            <Button fluid basic>
                              <Icon name="file excel outline" />
                              Exporter
                            </Button>
                          </Form.Field> */}
                          </Form>
                        </Message.Content>
                      </Message>
                    </Grid.Row>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </Accordion>
      </div>
      <div className="folders-table-container" id="folders-table-container">
        <Table sortable striped selectable className="folders-table">
          <Table.Header className="sticky-table-header">
            <Table.Row>
              {headers.map((header) => {
                return (
                  <Table.HeaderCell
                    key={header.field}
                    sorted={
                      sort.column === header.field ? sort.direction : null
                    }
                    onClick={() => header.sortable && sortBy(header.field)}
                    textAlign={header.center ? 'center' : 'left'}
                  >
                    {header.label}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {orderBy(
              data,
              [sort.column],
              [sort.direction === 'ascending' ? 'asc' : 'desc'],
            ).map(renderRow)}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
