import { IBank, IBanksFilter } from '../components/Banks/interface';
import { get, post, patch } from './apiService';

export const getBankList = async function (filter?: IBanksFilter) {
  return await get('banks', filter);
};
export const createBank = async function (Bank: IBank) {
  return await post('banks', Bank);
};
export const updateBank = async function (Bank: IBank) {
  return await patch('banks/' + Bank.id, Bank);
};
const BankService = {
  getBankList,
  createBank,
  updateBank,
};
export default BankService;
