import React, { ReactNode, useEffect, useState } from 'react';
import {
  authenticateUser,
  resetUserPassword,
  isError,
  isLoading,
  getError,
  getInfo,
} from './loginSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { isAuthenticated } from '../../services/authenticationService';
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Segment,
  Transition,
} from 'semantic-ui-react';

const VIEWS = {
  LOGIN: 'LOGIN',
  FIRST_LOGIN: 'FIRST_LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};
function ResetPasswordView() {
  return <div>Réinitialisation du mot de passe</div>;
}
function FirstLoginView() {
  return <div>Première connexion</div>;
}

export function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [view, setView] = useState(VIEWS.LOGIN);
  const [animate, setAnimate] = useState(true);
  const dispatch = useAppDispatch();

  const loading = useAppSelector(isLoading);
  const error = useAppSelector(getError);
  let info = useAppSelector(getInfo);
  if (view === VIEWS.LOGIN) info = '';
  if (isAuthenticated()) {
    window.location.reload();
  }

  const transitions = ['jiggle', 'flash', 'shake', 'pulse', 'tada', 'bounce'];
  const transition =
    transitions[Math.floor(Math.random() * transitions.length)];
  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 50);
  });
  const BackFooter = () => {
    return (
      <div className="login-action-links">
        <Button
          primary
          fluid
          size="large"
          loading={loading}
          onClick={resetPassword}
          icon
          labelPosition="right"
        >
          Valider
          <Icon name="checkmark" />
        </Button>

        <br />

        <a onClick={() => setView(VIEWS.LOGIN)} className="login-action-link">
          <Icon name="arrow alternate circle left outline" />
          Retour à l'authentification
        </a>
      </div>
    );
  };

  const resetPassword = (values: any) => {
    dispatch(resetUserPassword({ email, view }));
  };
  const login = (values: any) => {
    dispatch(authenticateUser({ email, password }));
  };

  const getCurrentView = (): ReactNode => {
    switch (view) {
      case VIEWS.FIRST_LOGIN:
        return FirstLoginView();
      case VIEWS.FORGOT_PASSWORD:
        return ResetPasswordView();
    }
    return <div>Authentification</div>;
  };
  const getCurrentFooter = () => {
    if ([VIEWS.FIRST_LOGIN, VIEWS.FORGOT_PASSWORD].includes(view))
      return BackFooter();

    return (
      <div className="login-action-links">
        <Button
          primary
          fluid
          size="large"
          loading={loading}
          onClick={login}
          icon
          labelPosition="right"
        >
          Connexion
          <Icon name="sign in" />
        </Button>

        <br />
        <a
          onClick={() => setView(VIEWS.FIRST_LOGIN)}
          className="login-action-link"
        >
          <Icon name="magic" />
          première connexion ?
        </a>
        <br />
        <a
          onClick={() => setView(VIEWS.FORGOT_PASSWORD)}
          className="login-action-link"
        >
          <Icon name="lock" />
          mot de passe oublié ?
        </a>
      </div>
    );
  };
  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Transition animation={transition} duration={1000} visible={animate}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" className="egg-violet" textAlign="center">
            <Image src="/static/logo-carre.png" /> {getCurrentView()}
          </Header>
          <Segment>
            <Form size="large">
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="E-mail"
                value={email}
                onChange={(e, { value }) => setEmail(value)}
              />
              {view === VIEWS.LOGIN && (
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Mot de passe"
                  type="password"
                  value={password}
                  onChange={(e, { value }) => setPassword(value)}
                />
              )}

              {getCurrentFooter()}

              {error || info ? (
                <Message
                  size="mini"
                  error={!info}
                  compact
                  icon={info ? 'info circle' : 'warning circle'}
                  content={error || info}
                />
              ) : null}
            </Form>
          </Segment>
        </Grid.Column>
      </Transition>
    </Grid>
  );
}
