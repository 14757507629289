import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchUsers, selectUserLists, isLoading, getFilter } from './userSlice';
import { Table, Container, Button, Icon } from 'semantic-ui-react';
import { getActive } from '../Menu/topMenuSlice';
import { menuItems, userTypeAdmin } from '../../../config/constants';
import moment from '../../../helpers/moment';
import { UserComponent } from './user';
import { UserFilterComponent } from './filter';
import { IUsersFilter } from './interface';
import { haveValues } from '../../../helpers/common';

export const UsersListPage = () => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(selectUserLists);
  const loading = useAppSelector(isLoading);
  const initialFilter: IUsersFilter | undefined = useAppSelector(getFilter);

  useEffect(() => {
    if (!users.userList?.total) {
      dispatch(fetchUsers(initialFilter));
    }
  }, [dispatch]);

  const menu = useAppSelector(getActive);
  if (menu !== menuItems.users) return null;
  return (
    <div>
      <Container textAlign="right">
        <UserFilterComponent
          trigger={
            <Button
              icon
              labelPosition="right"
              loading={loading}
              basic={!haveValues(initialFilter)}
              primary={haveValues(initialFilter)}
            >
              Filtre
              <Icon name="search" />
            </Button>
          }
        />
        <UserComponent
          key={'usercompo'}
          loading={loading}
          trigger={
            <Button basic icon labelPosition="right">
              Nouveau
              <Icon name="user" />
            </Button>
          }
        />
      </Container>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Dossiers</Table.HeaderCell>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            <Table.HeaderCell>Prénom</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Créé le</Table.HeaderCell>
            <Table.HeaderCell>Date du mot de passe</Table.HeaderCell>
            <Table.HeaderCell>Dernière connexion</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.userList?.data.map((user) => {
            return (
              <Table.Row
                key={user.id}
                positive={user.access_mode === userTypeAdmin}
              >
                <Table.Cell>{user.bank}</Table.Cell>
                <Table.Cell>{user.folders?.length || 0}</Table.Cell>
                <Table.Cell>{user.lastName}</Table.Cell>
                <Table.Cell>{user.firstName}</Table.Cell>
                <Table.Cell>{user.access_mode}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>
                  {moment(user.createdAt).format('DD/MM/YYYY')}
                </Table.Cell>
                <Table.Cell>
                  {moment(user.resetPasswordAt).isValid() &&
                    moment(user.resetPasswordAt).format('DD/MM/YYYY HH:mm') +
                      ' (' +
                      moment().diff(user.resetPasswordAt, 'days') +
                      'j/' +
                      (user.passwordExpiryDays || '120') +
                      ')'}
                </Table.Cell>
                <Table.Cell>
                  {moment(user.lastConnection).isValid() &&
                    moment(user.lastConnection).format('DD/MM/YYYY HH:mm') +
                      ' (' +
                      moment().diff(user.lastConnection, 'days') +
                      'j)'}
                </Table.Cell>
                <Table.Cell>
                  <UserComponent
                    user={user}
                    trigger={<Button icon="edit" basic compact />}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {/* <div className="pagination-container">
        <Pagination
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={25}
        />
      </div> */}
    </div>
  );
};
