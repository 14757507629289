import React, { ReactNode, useEffect, useState } from 'react';
import {
  authenticateUser,
  changeUserPassword as changePassword,
  getInfo,
  getError,
  isLoading,
  checkTokens,
} from '../Login/loginSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { isAuthenticated } from '../../services/authenticationService';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import './Login.module.css';
const PASSWORD_REGEX = [
  { regex: /^.{12,}$/, label: "Formé d'un minimum de 12 caractères." },
  { regex: /[A-Z]/, label: 'Au moins une lettre majuscule.' },
  { regex: /[a-z]/, label: 'Au moins une lettre minuscule.' },
  { regex: /[0-9]/, label: 'Au moins un chiffre.' },
  {
    regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
    label: 'Au moins un caractère spécial: !"#$%&\'()*+,-./:;<=>?@[]^_`{|}~',
  },
];
const VIEWS = {
  LOGIN: 'LOGIN',
  FIRST_LOGIN: 'FIRST_LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
};
function ResetPasswordView() {
  return <div>Réinitialisation du mot de passe</div>;
}
function FirstLoginView() {
  return <div>Première connexion</div>;
}

export function ForgotPassword() {
  const params = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [view, setView] = useState(VIEWS.LOGIN);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(isLoading);
  const error = useAppSelector(getError);
  const info = useAppSelector(getInfo);
  if (isAuthenticated()) {
    window.location.reload();
  }

  useEffect(() => {
    if (error === undefined) {
      dispatch(checkTokens(params));
    }
  });
  const correctPassword = () => {
    return (
      !error &&
      password === password2 &&
      !PASSWORD_REGEX.some((p) => !password.match(p.regex))
    );
  };

  const changeUserPassword = (values: any) => {
    if (correctPassword())
      dispatch(changePassword({ email, password, ...params }));
  };

  const getCurrentView = (): ReactNode => {
    switch (view) {
      case VIEWS.FIRST_LOGIN:
        return FirstLoginView();
      case VIEWS.FORGOT_PASSWORD:
        return ResetPasswordView();
    }
    return <div>Réinitialiser le mot de passe</div>;
  };
  const disabled = !correctPassword();

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" className="egg-violet" textAlign="center">
          <Image src="/static/logo-carre.png" /> {getCurrentView()}
        </Header>
        <Segment>
          {}
          <Dimmer active={loading}>
            <Loader />
          </Dimmer>
          {error || info ? (
            <Message
              size="mini"
              negative={!info}
              compact
              icon={info ? 'info circle' : 'warning circle'}
              content={error || info}
            />
          ) : (
            <Form size="large">
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="E-mail"
                value={email}
                name={Math.random()}
                onChange={(e, { value }) => setEmail(value)}
              />
              <Form.Input
                fluid
                name={Math.random()}
                icon="lock"
                iconPosition="left"
                placeholder="Mot de passe"
                type="password"
                value={password}
                onChange={(e, { value }) => setPassword(value)}
              />
              <List className="password-rules-list">
                {PASSWORD_REGEX.map((p) => (
                  <List.Item key={p.label}>
                    <List.Icon
                      name="check circle"
                      color={password.match(p.regex) ? 'green' : 'red'}
                    />
                    <List.Content>{p.label}</List.Content>
                  </List.Item>
                ))}
                <List.Item key="confirm">
                  <List.Icon
                    name="check circle"
                    color={password === password2 ? 'green' : 'red'}
                  />
                  <List.Content>
                    Les deux mots de passes doivent correspondre.
                  </List.Content>
                </List.Item>
              </List>
              <Form.Input
                fluid
                icon="lock"
                name={Math.random()}
                iconPosition="left"
                placeholder="Confirmation du mot de passe"
                type="password"
                value={password2}
                onChange={(e, { value }) => setPassword2(value)}
              />
              <Button
                primary
                fluid
                size="large"
                loading={loading}
                disabled={disabled}
                onClick={changeUserPassword}
                icon
                labelPosition="right"
              >
                Valider
                <Icon name="checkmark" />
              </Button>
            </Form>
          )}
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
