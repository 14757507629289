import React, { useState } from 'react';

import { Button, Form, Icon, Message, Modal } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchBanks, getFilter, isLoading } from './bankSlice';
import { IBanksFilter } from './interface';

export const BankFilterComponent = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const initialFilter: IBanksFilter | undefined = useAppSelector(getFilter);
  const loading = useAppSelector(isLoading);
  const emptyFilter = {
    firstName: '',
    lastName: '',
    email: '',
    notify_email: '',
    access_mode: '',
    bank: '',
  };
  const initialState: IBanksFilter = initialFilter || emptyFilter;
  const [filter, setFilter] = useState(initialState);

  const setField = (e: any, { name, value, required }: any) => {
    if (value && name === 'lastName') value = value.toUpperCase();
    setFilter({ ...filter, [name]: value });
  };
  const search = async () => {
    dispatch(fetchBanks(filter));

    setOpen(false);
    //setFilter(initialState);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={props.trigger}
    >
      <Modal.Header>
        <Icon name="search" /> Filtre
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message floating>
            <Form onSubmit={search}>
              <Form.Group widths="equal">
                <Form.Select
                  label="Type d'accès"
                  placeholder="Type d'accès"
                  options={[
                    { key: 'all', value: ' ', text: 'Tous' },
                    {
                      key: 'banque',
                      value: 'banque',
                      text: 'Banque/Assurance',
                    },
                    { key: 'admin', value: 'admin', text: 'Admin (EGG)' },
                  ]}
                  name="access_mode"
                  value={filter.access_mode}
                  onChange={setField}
                />
                {filter.access_mode === 'banque' ? (
                  <Form.Input
                    label="Client"
                    placeholder="Client"
                    name="bank"
                    value={filter.bank}
                    onChange={setField}
                  />
                ) : (
                  <Form.Field />
                )}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Nom"
                  placeholder="Nom"
                  name="lastName"
                  value={filter.lastName}
                  onChange={setField}
                />
                <Form.Input
                  label="Prénom"
                  placeholder="Prénom"
                  name="firstName"
                  value={filter.firstName}
                  onChange={setField}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Email"
                  placeholder="Email"
                  name="email"
                  value={filter.email}
                  onChange={setField}
                />
              </Form.Group>
            </Form>
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          onClick={() => setFilter(emptyFilter)}
          basic
          icon
          labelPosition="right"
        >
          Initialiser <Icon name="undo" />
        </Button>
        <Button
          loading={loading}
          onClick={search}
          primary
          icon
          labelPosition="right"
        >
          Filtrer <Icon name="search" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
