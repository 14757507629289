const env = {
  development: {
    BASE_URL: 'http://localhost:3334/',
  },
  production: {
    BASE_URL: 'https://api.egg-banques.com/',
  },
  test: {
    BASE_URL: 'http://localhost:3334/',
  },
};

export const env_var = env[process.env.NODE_ENV];
