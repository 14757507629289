import axios from 'axios';
import { env_var } from '../../config/env';
import { getFormDataHeader, getHeaderInfo } from '../../helpers/tokenCreator';
import { removeTokens } from './localStorage';
import toasts from '../../helpers/toast';

axios.defaults.baseURL = env_var.BASE_URL;

const readFile = (file: any) => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing file'));
    };

    fileReader.onload = () => {
      resolve(JSON.parse(fileReader.result?.toString() || '{}'));
    };

    fileReader.readAsText(file);
  });
};
const handleResponse = async (response: any) => {
  if (!response) {
    throw new Error("Erreur, l'API est inaccessible");
  }
  if (response.statusText !== 'OK' && response.data instanceof Blob) {
    response.data = await readFile(response.data);
  }

  if (response.status === 401) {
    removeTokens();
    window.location.replace('/');
  }
  if (response.data?.error && response.data?.message) {
    if (!Array.isArray(response.data.message))
      response.data.message = [response.data.message];
    response.data.message.forEach(toasts.error);
    throw new Error(response.data.message.join('-'));
  }

  if (response.data?.status !== 'OK') {
    return response.data;
  }
  return response;
};

export const post = async function (
  url: string,
  body: any = {},
  headers: any = {},
) {
  let header = await getHeaderInfo(headers);
  try {
    let resp = await axios.post(env_var.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const patch = async function (
  url: string,
  body: any,
  headers: any = {},
) {
  let header = await getHeaderInfo(headers);
  try {
    let resp = await axios.patch(env_var.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const get = async function (url: any, params: any = {}) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.get(url, { ...header, params });
    return handleResponse(resp);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};
export const getBlob = async function (url: any) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios({
      url,
      method: 'GET',
      responseType: 'blob',
      ...header,
    });
    return handleResponse(resp);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

export const put = async function (body: any, url: any) {
  let header = await getHeaderInfo();

  try {
    let resp = await axios.put(env_var.BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

export const deleteApi = async function (url: any) {
  let header = await getHeaderInfo();

  try {
    let resp = await axios.delete(env_var.BASE_URL + url, header);

    return handleResponse(resp);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

export const postImage = async function (url: string, body: any) {
  let header = await getFormDataHeader();
  var formData = new FormData();
  formData.append('file', body);
  try {
    let resp = await axios.put(env_var.BASE_URL + url, formData, header);
    return handleResponse(resp);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};
