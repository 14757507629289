export interface IpaginatedFolders {
  data: IFolder[];
  total: number;
}
export interface KeyDates {
  ENQ?: string | null;
  VFR?: string | null;
  PRD?: string | null;
  DRN?: string | null;
  DONE?: string | null;

  CONC?: string | null;

  RES?: string | null;
}

export interface Heir {
  id: string;
  createdAt?: string;
  updatedAt?: Date;
  active: boolean;

  type?: string;
  quality?: string;
  firstName?: string;
  lastName?: string;

  code?: string;
  name?: string;
  reference?: string;

  maritalStatus?: string;
  civ?: string;

  address?: string;
  postCode?: string;
  city?: string;
  country?: string;

  birthdate?: string;
  deathdate?: string;
  comments?: string;
}
export enum FolderType {
  CAV = 'CAV',
  BQ = 'BQ',
}
export interface IFolder {
  id: string;
  type?: FolderType;

  idGX: string;
  bank?: string;
  status?: keyof KeyDates;
  keyDates?: KeyDates;
  createdAt?: string;
  updatedAt?: Date;
  lastSyncGX?: Date;
  firstName: string;
  lastName: string;

  reference: string;
  code?: string;
  name?: string;
  birthdate?: Date | null;
  deathdate: Date | null;
  cav: string;
  liq: string;
  comments: string;
  documents: Document[];
  certificate?: Document[];
  messages: FolderMessage[];
  heirs: Heir[];
  acte?: string;
  user?: userLight;
  notary?: string;
  maritalStatus?: string;
  useName?: string;
  address?: string;
  postCode?: string;
  city?: string;
  country?: string;

  cdc?: boolean;
  domaines?: boolean;
}
export type Document = {
  id: string;
  folderId?: string;
  type: string;
  name?: string;
  filename: string;
  path: string;
  createdAt: string;
  updatedAt: string;
  user?: User;
  fieldname?: string;
};
export type userLight = {
  id: string;
  firstName: string;
  lastName: string;
  bank: string;
};
export type FolderMessage = {
  id?: string;
  createdAt?: string;
  type?: string;
  content: string;
  notifyBank: boolean;
  notifyEGG: boolean;
  user?: userLight;
  username?: string;
  folderId?: string;
  opened?: boolean;
};

type User = {
  id: string;
  firstName: string;
  lastName: string;
  bank: string;
};
export interface IFoldersFilter {
  firstName?: string;
  lastName?: string;
  deceased?: string;
  reference?: string;
  code?: string;
  type?: string;

  bank?: string;
  status?: string;
  newDoc?: boolean;
  newFolder?: boolean;
  newNote?: boolean;
}

export const FolderStatus = {
  ENQ: {
    code: 'ENQ',
    name: ['Recherches en cours', 'Enquête contextuelle'],
    icon: 'search',
    color: '#519DE9',
    target: 'common',
    order: 1,
  },

  VFR: {
    code: 'VFR',
    name: ['Recherches terminées', 'Attente du retour des héritiers'],
    icon: 'group',
    color: '#004B95',
    target: 'BQ',
    order: 2,
  },

  PRD: {
    code: 'PRD',
    name: ['Préparation remise', 'Dossier notaire'],
    icon: 'wpforms',
    color: '#B2B0EA',
    target: 'BQ',
    order: 3,
  },
  DRN: {
    code: 'DRN',
    name: ['Remis au notaire'],
    icon: 'law',
    color: '#7CC674',
    target: 'BQ',

    order: 4,
  },

  CONC: {
    code: 'CONC',
    name: ['Préparation des conclusions'],
    icon: 'wpforms',
    color: '#B2B0EA',
    target: 'CAV',

    order: 1,
  },

  RES: {
    code: 'RES',
    name: ['Résultat envoyé'],
    icon: 'check',
    color: '#7CC674',
    target: 'CAV',
    order: 2,
  },

  DONE: {
    code: 'DONE',
    name: ['Clôturé'],
    icon: 'archive',
    color: '#BDE2B9',
    target: 'common',
    order: 9,
  },
};

export const types: any = {
  [FolderType.BQ]: 'Bancaire',
  [FolderType.CAV]: 'Assurance-vie',
};
