export function forceDownload(rawData: BlobPart, filename: string) {
  const url = window.URL.createObjectURL(new Blob([rawData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
  return rawData;
}
export function capitalizeFirstLetter(string: string) {
  if (!!!string) string = '';
  const words = string.split(' ');

  return words
    .map((word) => {
      return word[0] && word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
}
export function haveValues(filter: any) {
  return filter && Object.keys(filter).some((key: string) => !!filter[key]);
}
