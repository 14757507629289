import React, { forwardRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';

import { getActive } from '../Menu/topMenuSlice';
import { menuItems, userTypeAdmin } from '../../../config/constants';

import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

import 'react-datepicker/dist/react-datepicker.css';

import './folder.css';

import { getUser } from '../../services/localStorage';
import { FolderType, IFolder } from './interface';
import { Form } from 'semantic-ui-react';
import toasts from '../../../helpers/toast';

registerLocale('fr', fr);
const user = getUser();
const IS_ADMIN = user.bank === '';
type folderEGGProps = {
  folder: IFolder;
  onChange?: (folder: IFolder) => void;
};
export const FolderEGG = (props: folderEGGProps) => {
  const dispatch = useAppDispatch();
  const { onChange } = props;

  const [folder, setFolder] = useState(props.folder);

  const setField = (e: any, { name, value, required }: any) => {
    if (['cdc', 'domaines'].includes(name)) {
      value = !!value;
    }
    const updatedFolder = { ...folder, [name]: value };
    setFolder(updatedFolder);
    props.onChange && props.onChange(updatedFolder);
  };

  const types = [
    {
      value: FolderType.CAV,
      text: 'Assurance-vie',
    },
    {
      value: FolderType.BQ,
      text: 'Bancaire',
    },
  ];

  return IS_ADMIN ? (
    <div>
      <Form>
        <Form.Group widths="equal">
          <Form.Input
            label="Dossier"
            placeholder="Dossier"
            name="code"
            value={folder.code}
            onChange={setField}
            required
          />
          <Form.Dropdown
            options={types}
            placeholder="Type"
            name="type"
            label={<label>Type</label>}
            selection
            value={folder.type}
            onChange={setField}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Notaire"
            placeholder="Notaire"
            name="notary"
            value={folder.notary}
            onChange={setField}
          />
          <Form.Field />
        </Form.Group>
      </Form>
    </div>
  ) : null;
};
