import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Bankservice from '../../services/bankService';
import { RootState } from '../../store';
import { IpaginatedBanks, IBank, IBanksFilter } from './interface';
import { removeTokens } from '../../services/localStorage';

export interface IBanksList {
  isLoading: boolean;
  BankList?: IpaginatedBanks;
  selected?: IBank;
  filter?: IBanksFilter;
}
const initialState: IBanksList = { isLoading: false };
export const BankListSlice = createSlice({
  name: 'BankList',
  initialState,
  reducers: {
    start: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    update: (state, action: PayloadAction<any>) => {
      let data = state.BankList?.data;
      if (state.BankList) {
        data = [...state.BankList.data].map((Bank) => {
          if (Bank.id === action.payload.selected.id)
            return action.payload.selected;
          return Bank;
        });
      }
      return {
        ...state,
        BankList: { ...state.BankList, data },
        ...action.payload,
        isLoading: false,
      };
    },
    error: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});
export const fetchBanks = (filter?: IBanksFilter) => async (dispatch: any) => {
  dispatch(start({ filter }));
  try {
    const BankLists = await Bankservice.getBankList(filter);
    dispatch(success({ BankList: BankLists }));
  } catch (err) {
    dispatch(error());
  }
};

export const createBank =
  (data: IBank, cb = (Bank: IBank) => {}) =>
  async (dispatch: any) => {
    dispatch(start({}));
    try {
      const Bank = await Bankservice.createBank(data);
      if (Bank?.id) dispatch(success({ selected: Bank }));
      else dispatch(error());
      cb(Bank);
      // dispatch(fetchBanks());
    } catch (err) {
      dispatch(error());
    }
  };

export const updateBank =
  (data: IBank, cb = (Bank: IBank) => {}) =>
  async (dispatch: any) => {
    dispatch(start({}));
    try {
      const Bank = await Bankservice.updateBank(data);
      if (Bank?.id) dispatch(update({ selected: Bank }));
      else dispatch(error());
      cb(Bank);
      // dispatch(fetchBanks());
    } catch (err) {
      dispatch(error());
    }
  };
export const { start, success, error, update } = BankListSlice.actions;
export const selectBankLists = (state: RootState) => {
  return state.documentList;
};

export const isLoading = (state: RootState) => {
  return state.documentList.isLoading;
};

export const getFilter = (state: RootState) => {
  return state.documentList.filter;
};

export const BanksListReducer = BankListSlice.reducer;
export const logout = (reload = false) => {
  removeTokens();
  if (reload) window.location.reload();
};
