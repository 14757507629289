import { configureStore } from '@reduxjs/toolkit';
import { authenticationReducer } from './components/Login/loginSlice';
import { usersListReducer } from './components/Users/userSlice';
import { menuReducer } from './components/Menu/topMenuSlice';
import { foldersListReducer } from './components/Folders/folderSlice';
import { BanksListReducer } from './components/Banks/bankSlice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    folderList: foldersListReducer,
    userList: usersListReducer,
    menu: menuReducer,
    documentList: BanksListReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
