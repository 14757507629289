import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchBanks, selectBankLists, isLoading, getFilter } from './bankSlice';
import { Table, Container, Button, Icon } from 'semantic-ui-react';
import { getActive } from '../Menu/topMenuSlice';
import { menuItems } from '../../../config/constants';
import moment from '../../../helpers/moment';
import { BankComponent } from './bank';
import { BankFilterComponent } from './filter';
import { IBanksFilter } from './interface';
import { haveValues } from '../../../helpers/common';

export const BanksListPage = () => {
  const dispatch = useAppDispatch();

  const Banks = useAppSelector(selectBankLists);
  const loading = useAppSelector(isLoading);
  const initialFilter: IBanksFilter | undefined = useAppSelector(getFilter);
  useEffect(() => {
    if (!Banks.BankList?.total) dispatch(fetchBanks(initialFilter));
  }, [dispatch]);

  const menu = useAppSelector(getActive);
  if (menu !== menuItems.documents) return null;
  return (
    <div>
      <Container textAlign="right">
        <BankFilterComponent
          trigger={
            <Button
              icon
              labelPosition="right"
              loading={loading}
              basic={!haveValues(initialFilter)}
              primary={haveValues(initialFilter)}
            >
              Filtre
              <Icon name="search" />
            </Button>
          }
        />
        <BankComponent
          trigger={
            <Button basic icon labelPosition="right">
              Nouveau
              <Icon name="add" />
            </Button>
          }
        />
      </Container>
      <Table striped attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Client</Table.HeaderCell>
            <Table.HeaderCell>Dossiers</Table.HeaderCell>
            <Table.HeaderCell>Nom</Table.HeaderCell>
            <Table.HeaderCell>Prénom</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Créé le</Table.HeaderCell>
            <Table.HeaderCell>Dernière connexion</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {Banks.BankList?.data.map((Bank: any) => {
            return (
              <Table.Row key={Bank.id}>
                <Table.Cell>{Bank.bank}</Table.Cell>
                <Table.Cell>{Bank.folders?.length || 0}</Table.Cell>
                <Table.Cell>{Bank.lastName}</Table.Cell>
                <Table.Cell>{Bank.firstName}</Table.Cell>
                <Table.Cell>{Bank.access_mode}</Table.Cell>
                <Table.Cell>{Bank.email}</Table.Cell>
                <Table.Cell>
                  {moment(Bank.createdAt).format('DD/MM/YYYY')}
                </Table.Cell>
                <Table.Cell>
                  {moment(Bank.lastConnection).isValid() &&
                    moment(Bank.lastConnection).format('DD/MM/YYYY HH:mm')}
                </Table.Cell>
                <Table.Cell>
                  <BankComponent
                    Bank={Bank}
                    trigger={<Button icon="edit" basic compact />}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {/* <div className="pagination-container">
        <Pagination
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={25}
        />
      </div> */}
    </div>
  );
};
