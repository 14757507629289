import {
  Document,
  FolderMessage,
  Heir,
  IFolder,
  IFoldersFilter,
  KeyDates,
} from '../components/Folders/interface';
import { deleteApi, get, getBlob, patch, post } from './apiService';

export const getFolderList = async function (filter?: IFoldersFilter) {
  return await get('folders', filter);
};
export const getDocument = async function (document: Document) {
  return await getBlob('folders/' + document.folderId + '/' + document.id);
};
export const addDocuments = async function (
  folderId: string,
  documents: Document[],
) {
  return await post('folders/' + folderId + '/document', documents);
};
export const addMessage = async function (
  folderId: string,
  message: FolderMessage,
) {
  return await post('folders/' + folderId + '/message', message);
};

export const setFolderHeirActive = async function (
  folder: IFolder,
  heir: Heir,
  active: boolean,
) {
  return await post('folders/' + folder.id + '/' + heir.id + '/active', {
    active,
  });
};
export const syncGX = async function (folder: IFolder) {
  return await post('folders/' + folder.id + '/' + folder.code + '/sync');
};
export const createFolder = async function (folder: IFolder) {
  const form_data = new FormData();
  const obj: any = { ...folder };
  for (const key in obj) {
    if (obj[key] instanceof FileList) {
      for (let x = 0; x < obj[key].length; x++) {
        form_data.append(key + '[]', obj[key][x]);
      }
    } else form_data.append(key, obj[key]);
  }
  return await post('folders', form_data, {
    'Content-Type': 'multipart/form-data',
  });
};

export const updateFolder = async function (folder: IFolder) {
  return await patch('folders/' + folder.id, folder);
};
export const deleteFolder = async function (folder: IFolder) {
  return await deleteApi('folders/' + folder.id);
};

const FolderService = {
  getFolderList,
  createFolder,
  updateFolder,
  deleteFolder,
  getDocument,
  addDocuments,
  addMessage,
  syncGX,
  setFolderHeirActive,
};
export default FolderService;
