import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  fetchFolders,
  selectFolderLists,
  getFilter,
  setFilterFolders,
  getFilteredFolders,
} from './folderSlice';
import { Grid, Message } from 'semantic-ui-react';
import ReactEcharts from 'echarts-for-react';
import {
  IFolder,
  IFoldersFilter,
  FolderStatus,
  FolderType,
  types,
} from './interface';
import './list.css';
const getFolderStatus = () => {
  return Object.keys(FolderStatus);
};
const getStats = (target: FolderType, data: IFolder[]) => {
  return getFolderStatus()
    .map((_code) => {
      const code = _code as keyof typeof FolderStatus;
      if (!['common', target].includes(FolderStatus[code].target))
        return undefined;
      const value = data.filter(
        (folder: IFolder) => folder.status === code,
      ).length;
      const status = code as keyof typeof FolderStatus;
      // color.push(FolderStatus[status].color);
      return {
        value,
        name: FolderStatus[status].name[0],
        code: FolderStatus[status].code,
        type: target,
      };
    })
    .filter(Boolean);
};
const getColors = (target: FolderType) => {
  const colors = getFolderStatus()
    .map((_code) => {
      const code = _code as keyof typeof FolderStatus;
      if (!['common', target].includes(FolderStatus[code].target)) return null;

      const status = code as keyof typeof FolderStatus;

      return FolderStatus[status].color;
    })
    .filter(Boolean);
  colors.unshift('#73C5C5');
  return colors;
};

export const FolderPie = (props: any) => {
  const folderType = props.type;
  const folderLabel = types[folderType];
  const dispatch = useAppDispatch();

  const folders = useAppSelector(selectFolderLists);
  const initialFilter: IFoldersFilter | undefined = useAppSelector(getFilter);

  const [clickedStatus, setClickedStatus] = useState('');

  const filter = useAppSelector(getFilter);
  let data: any = [
    ...useAppSelector((state) => getFilteredFolders(state, false)),
  ];
  const statusData: any = useAppSelector((state) =>
    getFilteredFolders(state, true),
  ).filter((folder: IFolder) => folder.type === folderType);

  const stats = getStats(folderType, statusData);
  useEffect(() => {
    if (!folders.folderList?.total) dispatch(fetchFolders(initialFilter));
  }, [dispatch]);

  const other = statusData.filter(
    (folder: any) => folder.status === 'DEVIS' && folder.type === folderType,
  ).length;
  stats.unshift({
    value: other,
    name: 'Nouveau',
    code: 'DEVIS',
    type: folderType,
  });
  const serie = {
    color: getColors(folderType),
    folderType: folderType,
    name: folderLabel,
    type: 'pie',
    radius: ['70%', '100%'],
    center: ['50%', '90%'],
    emphasis: {
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowColor: 'rgba(0, 0, 0, 0.5)',
    },
    // adjust the start angle
    startAngle: 180,

    label: {
      show: true,
      formatter(param: any) {
        return param.value + ' ' + param.name + ' (' + param.percent * 2 + '%)';
      },

      position: 'outer',
      alignTo: 'none',
      bleedMargin: 5,
      fontSize: 10,
      // width: 100,
      overflow: 'break',
    },
    data: [
      ...stats,
      {
        // make an record to fill the bottom 50%
        value:
          stats.map((o: any) => o.value).reduce((a: any, b: any) => a + b, 0) ||
          1,
        itemStyle: {
          // stop the chart from rendering this piece
          color: 'none',
          decal: {
            symbol: 'none',
          },
        },
      },
    ],
  };

  const option = {
    animation: false,
    tooltip: {
      trigger: 'item',
    },

    legend: {
      top: '5%',
      right: '0',
      // doesn't perfectly work with our tricks, disable it
      selectedMode: false,
      orient: 'horizontal',
    },
    series: [serie],
  };

  const onChartReady = (chart: any) => {
    let seriesIndex = 0,
      dataIndex = stats;

    if (filter?.status) {
      chart.dispatchAction({
        type: 'highlight',
        seriesIndex,
        dataIndex: dataIndex.findIndex(
          (data: any) => data.code === filter?.status,
        ),
      });
    }
  };
  const onChartClick = (serie: any, chart: any) => {
    if (!serie.value) return;
    let name: any = serie.data.name;
    let status: any = serie.data.code;
    let type: any = serie.data.type;

    chart.dispatchAction({
      type: 'downplay',
      seriesIndex: serie.seriesIndex,
    });
    if (clickedStatus !== serie.data?.name) {
      chart.dispatchAction({
        type: 'highlight',
        seriesIndex: serie.seriesIndex,
        dataIndex: serie.dataIndex,
      });
    } else {
      status = undefined;
      type = undefined;
    }
    setClickedStatus(name);
    dispatch(setFilterFolders({ type, status }));
  };

  return statusData.length ? (
    <Grid.Column>
      <div className="reactEcharts-container">
        <Message className="filter-container" floating>
          <Message.Header>
            {folderLabel} - {statusData.length} dossier
            {statusData.length > 1 ? 's' : ''}
          </Message.Header>
          <Message.Content>
            {data.length ? (
              <div>
                <ReactEcharts
                  option={option}
                  onChartReady={onChartReady}
                  onEvents={{ click: onChartClick }}
                />
              </div>
            ) : (
              <Message info>
                <Message.Content>Aucun dossier trouvé</Message.Content>
              </Message>
            )}
          </Message.Content>
        </Message>
      </div>
    </Grid.Column>
  ) : null;
};
