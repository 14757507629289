import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  authenticate,
  resetPassword,
  checkUserToken,
  changePassword,
} from '../../services/authenticationService';
import { setTokens } from '../../services/localStorage';
import { RootState } from '../../store';
import toasts from '../../../helpers/toast';
export interface IAuthentication {
  isProcessingRequest: boolean;
  isError: boolean;
  accessToken?: string;
  error?: string;
  info?: string;
  user: any;
}
const initialState: IAuthentication = {
  isProcessingRequest: false,
  isError: false,
  error: undefined,
  info: undefined,
  user: {},
};
export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
        isProcessingRequest: true,
        isError: false,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isError: false,
        isProcessingRequest: false,
      };
    },
    error: (
      state,
      error: PayloadAction<any> | undefined = {
        type: '',
        payload: { message: '' },
      },
    ) => {
      return {
        ...state,
        isError: true,
        error: error.payload,
        isProcessingRequest: false,
      };
    },
    info: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        info: action.payload,
      };
    },
  },
});
export const authenticateUser = (userData: any) => async (dispatch: any) => {
  try {
    const authData = await authenticate(userData);
    if (authData.view === 'PASSWORD_EXPIRED') {
      dispatch(error(authData.message));

      return;
    }
    if (authData.error) {
      dispatch(error(authData.message));
    } else {
      setTokens(authData);
      dispatch(success(authData));
      window.location.replace('/');
    }
  } catch (err: any) {
    dispatch(error(err.message || 'E-mail ou mot de passe incorrect'));
  }
};

export const resetUserPassword = (userData: any) => async (dispatch: any) => {
  try {
    const authData = await resetPassword(userData);
    if (authData.message) {
      dispatch(info(authData.message));
    }
    if (authData.error) {
      throw new Error(authData.error);
    } else {
      dispatch(success(authData));
    }
  } catch (err) {
    dispatch(error('E-mail ou mot de passe incorrect'));
  }
};
export const checkTokens = (userData: any) => async (dispatch: any) => {
  try {
    const authData = await checkUserToken(userData);
    if (authData.error) {
      dispatch(error(authData.error));
    } else {
      dispatch(success(authData));
    }
  } catch (err) {
    dispatch(error(err));
  }
};
export const changeUserPassword = (userData: any) => async (dispatch: any) => {
  try {
    const authData = await changePassword(userData);
    if (authData.message) {
      dispatch(error(authData.message));
    }
    if (authData.error) {
      throw new Error(authData.error);
    } else {
      dispatch(success(authData));
      alert(
        'Votre mot de passe a été changé, cliquez sur OK pour vous reconnecter',
      );
      window.location.replace('/');
    }
  } catch (err) {
    dispatch(error('E-mail ou mot de passe incorrect'));
  }
};
export const { start, success, error, info } = authenticationSlice.actions;
export const selectAuthentication = (state: RootState) => state.authentication;
export const authenticationReducer = authenticationSlice.reducer;

export const isLoading = (state: RootState) => {
  return state.authentication.isProcessingRequest;
};
export const isError = (state: RootState) => {
  return state.authentication.isError;
};
export const getError = (state: RootState) => {
  return state.authentication.error;
};
export const getInfo = (state: RootState) => {
  return state.authentication.info;
};

export const getUser = (state: RootState) => {
  return state.authentication.error;
};
