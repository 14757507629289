import React, { forwardRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getDocument,
  isLoading,
  addDocuments,
  addMessage,
  syncFolder,
  setFolderHeirActive,
  updateFolder,
  deleteFolder,
} from './folderSlice';
import {
  Table,
  Grid,
  Button,
  Icon,
  Label,
  Card,
  Step,
  Form,
  Message,
  Modal,
  Tab,
  Menu,
  Feed,
  Checkbox,
  Popup,
  TabPane,
} from 'semantic-ui-react';
import { getActive } from '../Menu/topMenuSlice';
import { menuItems } from '../../../config/constants';
import moment from '../../../helpers/moment';
import {
  IFolder,
  Document,
  FolderStatus,
  FolderMessage,
  Heir,
  FolderType,
} from './interface';
import DatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

import 'react-datepicker/dist/react-datepicker.css';

import './folder.css';
import { isNew } from '../Common/utils';
import { toast } from 'react-toastify';
import { getUser } from '../../services/localStorage';
import { getNew } from './helpers';
import { cloneDeep, isEqual } from 'lodash';
import { FormPage } from '../Form';
import toasts from '../../../helpers/toast';
import { FolderEGG } from './folder.egg';

registerLocale('fr', fr);
const user = getUser();
const CAN_EDIT_KEYDATES = user.bank === '';
export const Folder = (props: any) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(isLoading);
  const initialFolderStatusData: any = {
    status: '',
    createFolder: 'n',
    folderCode: '',
    keyDates: props.folder.keyDates || {},
  };
  if (props.folder.birthdate === 'null') {
    props.folder.birthdate = null;
  }
  if (props.folder.deathdate === 'null') {
    props.folder.deathdate = null;
  }
  const initialMessage: FolderMessage = {
    content: '',
    notifyBank: false,
    notifyEGG: false,
    opened: false,
  };
  //const [newStatus, setNewStatus] = useState('');
  const [folderStatusData, setFolderStatusData] = useState(
    initialFolderStatusData,
  );
  const [folderEdit, setFolderEdit] = useState({} as IFolder);
  if (!isEqual(props.folder.keyDates, folderStatusData.keyDates))
    setFolderStatusData({
      ...initialFolderStatusData,
      keyDates: props.folder.keyDates || {},
    });
  const [newMessage, setNewMessage] = useState({ ...initialMessage });
  const downloadDocument = (document: Document) => {
    dispatch(getDocument(document));
  };
  useEffect(() => {
    setFolderStatusData({ ...folderStatusData, folderCode: props.folder.code });
  }, [dispatch]);
  //const setFolderStatus = (status: string) => {};
  const syncGX = (folder: IFolder) => {
    dispatch(syncFolder(folder));
  };
  const deleteFolderEdit = (folder: IFolder) => {
    if (
      window.confirm(
        'Êtes vous sûr de vouloir supprimer définitivement ce dossier ? \n\n\tClient \t: ' +
          (folder.user?.bank || '-') +
          '\n\tCode \t: ' +
          (folder.code || '-') +
          '\n\tRéf. \t: ' +
          (folder.reference || '-'),
      )
    ) {
      dispatch(deleteFolder(folder));
    }
  };

  const renderFolderActions = (folder: IFolder) => {
    if (user.bank !== '') return null;
    let buttons = null;

    switch (folder.status) {
      default:
        buttons = (
          <div>
            <Button negative onClick={() => deleteFolderEdit(folder)}>
              <Icon name="trash" /> Supprimer
            </Button>
            <Button onClick={() => setFolderEdit(folder)}>
              <Icon name="edit" /> Modifier
            </Button>
            <Button
              disabled={!folder.code}
              onClick={() => syncGX(folder)}
              loading={loading}
            >
              <Icon name="sync" /> Sync GX
            </Button>
            <Button disabled={!folder.idGX} onClick={() => openGX(folder.idGX)}>
              <Icon name="external" /> Ouvrir sur GX
            </Button>
          </div>
        );

        break;
    }
    return buttons;
  };
  const saveMessage = (folderId: string) => {
    if (!newMessage.content) return toast.error('Veuillez saisir un message');
    const { opened, ...message } = newMessage;
    dispatch(
      addMessage(folderId, message, (folder: IFolder) => {
        const newMessage = folder.messages[0];
        setTimeout(() => {
          const feed = document.querySelector('#message-' + newMessage.id);
          feed?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 500);
      }),
    );
    setNewMessage({
      content: '',
      notifyBank: true,
      notifyEGG: true,
      opened: false,
    });
  };

  const openGX = (idGX: string) => {
    window
      ?.open(
        'https://recherche.etudeguenifey.com/Orchard.GNX.Core/Case/Edit/' +
          idGX,
        '_blank',
      )
      ?.focus();
  };
  const setHeirActive = (folder: IFolder, heir: Heir, active: boolean) => {
    dispatch(
      setFolderHeirActive(folder, heir, active, (folder: IFolder) => {}),
    );
  };
  const editFolder = (folder: IFolder) => {
    const error = [
      { name: 'Nom', value: folder.lastName },
      { name: 'Prénom', value: folder.firstName },
      { name: 'Date de décès', value: folder.deathdate },
    ].some((field: any) => {
      return toasts.checkValue(field.value, field.name);
    });
    if (!error) {
      dispatch(
        updateFolder(folder, () => {
          //disable save + modal message
          setFolderEdit({} as IFolder);
          toasts.success('Dossier modifié avec succès');
        }),
      );
    }
  };
  const renderRowDetails = (folder: IFolder) => {
    const certificate = (folder.documents || []).filter((o) =>
      o.type.includes('certificate'),
    );
    const others = (folder.documents || []).filter(
      (o) => !o.type.includes('certificate'),
    );

    const DatePickerInput = forwardRef(
      ({ status, value, onClick }: any, ref) => {
        const code = status as keyof typeof FolderStatus;
        const icon: any = FolderStatus[code].icon || 'question circle  outline';
        return (
          <Step.Description className="keyDate" onClick={onClick}>
            <Popup
              wide
              position="bottom left"
              trigger={
                <div>
                  <Icon name="calendar alternate outline" />{' '}
                  {value || '--/--/----'}
                </div>
              }
            >
              <Popup.Content>
                Date de passage au statut{' '}
                <Label basic>
                  <Icon
                    name={icon}
                    style={{ color: FolderStatus[code].color }}
                  />
                  {FolderStatus[code].name}
                </Label>
                {value || ' non saisie'}
              </Popup.Content>
            </Popup>
          </Step.Description>
        );
      },
    );

    const fileInputRef: any = React.createRef();
    const haveNew = getNew(folder);
    const panes = [
      {
        menuItem: (
          <Menu.Item key="docs">
            <Icon name="file pdf" />
            Documents{' '}
            <Label className={haveNew.document ? 'is-new' : ''}>
              {(folder.documents || []).length}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            {!!certificate.length && (
              <Message className="documents-message" icon>
                <Icon name="file pdf" />
                <Message.Header>Acte de décès</Message.Header>
                <Message.Content>
                  <p>
                    {certificate.map((document, i) => (
                      <Label
                        key={i}
                        as="a"
                        className={
                          'file-label ' +
                          (isNew(document.createdAt) ? 'is-new' : '')
                        }
                        onClick={() => downloadDocument(document)}
                      >
                        <Icon name="file pdf outline" />
                        {document.filename}
                        {document.user ? (
                          <div className="document-creator-label">
                            {document.user.firstName} {document.user.lastName}{' '}
                            le {moment(document.createdAt).format('DD/MM/YYYY')}
                          </div>
                        ) : null}
                      </Label>
                    ))}
                  </p>
                </Message.Content>
              </Message>
            )}

            {!!others.length && (
              <Message className="documents-message" icon>
                <Icon name="file pdf" />
                <Message.Header>Autre documents</Message.Header>
                <Message.Content>
                  <p>
                    {others.map((document, i) => (
                      <Label
                        key={i}
                        as="a"
                        className={
                          'file-label ' +
                          (isNew(document.createdAt) ? 'is-new' : '')
                        }
                        onClick={() => downloadDocument(document)}
                      >
                        <Icon name="file pdf outline" />
                        {document.filename}{' '}
                        {document.user ? (
                          <div className="document-creator-label">
                            {document.user.firstName} {document.user.lastName}{' '}
                            le {moment(document.createdAt).format('DD/MM/YYYY')}
                          </div>
                        ) : null}
                      </Label>
                    ))}
                  </p>
                </Message.Content>
              </Message>
            )}
            <Form.Field>
              <Button
                floated="right"
                content="Ajouter un document"
                labelPosition="left"
                loading={loading}
                disabled={loading}
                icon="file"
                onClick={() => fileInputRef.current.click()}
              />
              <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={(e: any) => {
                  dispatch(
                    addDocuments(folder.id, e.target.files, (response: any) => {
                      toast.success('Opération effectuée avec succès');
                    }),
                  );
                }}
              />
            </Form.Field>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="heirs">
            <Icon name="group" />
            Héritiers{' '}
            <Label className={haveNew.heir ? 'is-new' : ''}>
              {(folder.heirs || []).filter((heir) => !!heir.active).length}
              {CAN_EDIT_KEYDATES ? (
                <span>/{(folder.heirs || []).length}</span>
              ) : null}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Table compact singleLine definition={CAN_EDIT_KEYDATES}>
              <Table.Header>
                <Table.Row>
                  {CAN_EDIT_KEYDATES ? <Table.HeaderCell /> : null}
                  <Table.HeaderCell>Héritier</Table.HeaderCell>
                  <Table.HeaderCell>Dates</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {folder.heirs.map((heir: Heir) => {
                  return (
                    <Table.Row
                      positive={CAN_EDIT_KEYDATES && heir.active}
                      negative={CAN_EDIT_KEYDATES && !heir.active}
                    >
                      {CAN_EDIT_KEYDATES ? (
                        <Table.Cell collapsing>
                          <Checkbox
                            toggle
                            disabled={loading}
                            checked={heir.active}
                            onChange={(e, { checked }) => {
                              setHeirActive(folder, heir, !!checked);
                            }}
                          />
                        </Table.Cell>
                      ) : null}
                      <Table.Cell>
                        {[heir.maritalStatus, heir.name].join(' ').trim() ||
                          '--'}
                        <br />
                        <Icon name="calendar alternate outline" />
                        <span className="heir-deathdate-value">
                          {heir.birthdate
                            ? 'N:' + moment(heir.birthdate).format('DD/MM/YYYY')
                            : '--'}
                        </span>
                        <span className="heir-deathdate-value">
                          <Icon name="calendar alternate outline" />
                          {heir.deathdate
                            ? 'D:' + moment(heir.deathdate).format('DD/MM/YYYY')
                            : '--'}
                        </span>
                        <br />
                        {heir.address || '--'}
                        <br />
                        {[heir.postCode, heir.city, heir.country]
                          .join(' ')
                          .trim() || '--'}
                      </Table.Cell>
                      <Table.Cell>
                        <span className="heir-date-label">Créé(e) le :</span>
                        {heir.createdAt
                          ? moment(heir.createdAt).format('DD/MM/YYYY')
                          : '--'}
                        <br />
                        <span className="heir-date-label">M.A.J le :</span>
                        {heir.updatedAt
                          ? moment(heir.updatedAt).format('DD/MM/YYYY')
                          : '--'}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Tab.Pane>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="messages">
            <Icon name="wechat" />
            Notes{' '}
            <Label className={haveNew.message ? 'is-new' : ''}>
              {(folder.messages || []).length}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <Feed className="message-feed">
              {[...(folder.messages || [])].map((message: FolderMessage) => {
                return (
                  <Feed.Event
                    key={message.id}
                    id={'message-' + message.id}
                    className={[
                      isNew(message.createdAt) ? 'is-new' : '',
                      message.user?.bank === '' ? 'egg' : '',
                    ].join(' ')}
                  >
                    <Feed.Label>
                      <Icon name="talk" flipped={'horizontally'} />
                    </Feed.Label>
                    <Feed.Content>
                      <Feed.Summary>
                        <Feed.User>
                          {message.user?.lastName} {message.user?.firstName}
                        </Feed.User>
                        <Feed.Date>
                          <Icon name="calendar alternate outline" />
                          {moment(message.createdAt).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                        </Feed.Date>
                      </Feed.Summary>
                      <Feed.Extra text>{message.content}</Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                );
              })}
            </Feed>

            {!newMessage.opened ? (
              <Form.Button
                floated="right"
                onClick={() => {
                  setNewMessage({
                    ...newMessage,
                    opened: true,
                  });
                  setTimeout(() => {
                    const tarea = document.querySelector(
                      '#note-area-' + folder.id,
                    ) as HTMLElement;
                    tarea?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'center',
                    });
                    tarea?.focus();
                  }, 500);
                }}
                loading={loading}
                disabled={loading}
              >
                <Icon name="talk" flipped={'horizontally'} /> Ajouter une note
              </Form.Button>
            ) : (
              <Form>
                <Form.TextArea
                  disabled={loading}
                  id={'note-area-' + folder.id}
                  label="Ajouter une note"
                  placeholder="Message..."
                  value={newMessage.content}
                  onChange={(event: any, { value }) => {
                    setNewMessage({
                      ...newMessage,
                      content: value as string, //event.target.value,
                    });
                  }}
                />
                <Form.Group widths={'16'}>
                  <Form.Field width={12}></Form.Field>

                  <Form.Field width={4}>
                    <div className="padding-top18">
                      <Form.Button
                        onClick={() => saveMessage(folder.id)}
                        loading={loading}
                        disabled={loading}
                      >
                        <Icon name="save" /> Ajouter
                      </Form.Button>
                    </div>
                  </Form.Field>
                </Form.Group>
              </Form>
            )}
          </Tab.Pane>
        ),
      },
    ];

    const types: any = {
      [FolderType.BQ]: 'Bancaire',
      [FolderType.CAV]: 'Assurance-vie',
    };
    return (
      <div>
        <Modal
          open={!!folderEdit?.id}
          onClose={() => setFolderEdit({} as IFolder)}
        >
          <Modal.Header>
            Modifier le dossier {folderEdit.reference}
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Tab
                menu={{ secondary: true }}
                panes={[
                  {
                    menuItem: 'Saisie Client',
                    render: () => (
                      <TabPane attached={false}>
                        <FormPage
                          folder={folderEdit}
                          onChange={(updatedFolder: IFolder) => {
                            setFolderEdit(updatedFolder);
                          }}
                        />
                      </TabPane>
                    ),
                  },
                  {
                    menuItem: 'EGG',
                    render: () => (
                      <TabPane attached={false}>
                        <FolderEGG
                          folder={folderEdit}
                          onChange={(updatedFolder: IFolder) => {
                            setFolderEdit(updatedFolder);
                          }}
                        />
                      </TabPane>
                    ),
                  },
                ]}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => editFolder(folderEdit)}
              primary
              loading={loading}
            >
              <Icon name="save" /> Valider
            </Button>
          </Modal.Actions>
        </Modal>

        <Grid columns={2}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="stepper-container">
                <Step.Group fluid>
                  {Object.keys(FolderStatus).map((key, index) => {
                    const keys = Object.keys(FolderStatus);
                    const code = key as keyof typeof FolderStatus;
                    const target: any = FolderStatus[code].target;
                    const ftypes = ['common', folder.type];
                    if (!ftypes.includes(target)) return null;
                    const icon: any =
                      FolderStatus[code].icon || 'question circle outline';

                    const active = folder.status === FolderStatus[code].code;
                    const disabled =
                      !!folder.keyDates?.DONE ||
                      (!(folder.keyDates && folder.keyDates[code]) &&
                        !active &&
                        keys.indexOf(folder.status as string) < index);

                    return (
                      <Step
                        disabled={CAN_EDIT_KEYDATES ? false : disabled}
                        active={active}
                        className={
                          !!folderStatusData.keyDates[code]
                            ? 'state-completed'
                            : ''
                        }
                        key={code}
                      >
                        <Icon
                          name={icon}
                          style={{
                            color:
                              (disabled || !folderStatusData.keyDates[code]) &&
                              !active
                                ? 'rgb(194 194 194)'
                                : FolderStatus[code].color,
                          }}
                        />
                        <Step.Content>
                          <Step.Title>
                            {FolderStatus[code].name[0]}
                            {FolderStatus[code].name[1] ? (
                              <div className="stepper-subtitle">
                                {FolderStatus[code].name[1]}
                              </div>
                            ) : null}
                          </Step.Title>
                          <DatePicker
                            popperContainer={({ children }) =>
                              createPortal(children, document.body)
                            }
                            dateFormat="dd/MM/yyyy"
                            locale="fr"
                            selected={
                              !!folderStatusData.keyDates[code]
                                ? moment(
                                    folderStatusData.keyDates[code],
                                  ).toDate()
                                : null
                            }
                            disabled={!CAN_EDIT_KEYDATES}
                            onChange={(date: Date) => {
                              const _folderStatus = {
                                ...folderStatusData,
                                keyDates: {
                                  ...folderStatusData.keyDates,
                                  [code]: moment(date).toISOString(),
                                },
                              };
                              setFolderStatusData(_folderStatus);
                              dispatch(
                                updateFolder({
                                  ...folder,
                                  keyDates: _folderStatus.keyDates,
                                }),
                              );
                            }}
                            customInput={<DatePickerInput status={code} />}
                          />
                        </Step.Content>
                      </Step>
                    );
                  })}
                </Step.Group>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="folder-body">
            <Grid.Column>
              <Card.Group itemsPerRow={2} stackable>
                <Card fluid>
                  <Card.Content>
                    <Form>
                      <Form.Group widths={'equal'}>
                        <Form.Field>
                          <label>Nom de naissance</label>
                        </Form.Field>
                        <Form.Field>
                          <Label basic style={{ width: '100%' }}>
                            {folder.useName || '-'}
                          </Label>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths={'equal'}>
                        <Form.Field>
                          <label>Nom d'usage</label>
                        </Form.Field>
                        <Form.Field>
                          <Label basic style={{ width: '100%' }}>
                            {folder.lastName || '-'}
                          </Label>
                        </Form.Field>
                      </Form.Group>

                      <Form.Field>
                        <label>Commentaires</label>
                      </Form.Field>
                      <Form.Field>
                        <Label
                          basic
                          fluid
                          style={{ width: '100%', textAlign: 'left' }}
                        >
                          {folder.comments || '--'}
                        </Label>
                      </Form.Field>
                    </Form>
                  </Card.Content>
                </Card>
                <Card>
                  <Card.Content>
                    <Form>
                      {folder.liq && (
                        <Form.Group widths={'equal'} inline>
                          <Form.Field style={{ padding: '0' }}>
                            <label>Avoirs</label>

                            {folder.cdc ? (
                              <div className="cdc-domaines">
                                {'CDC '}
                                <Icon name="check" color="green" />
                              </div>
                            ) : null}
                            {folder.domaines ? (
                              <div className="cdc-domaines">
                                {'Domaine '}
                                <Icon name="check" color="green" />
                              </div>
                            ) : null}

                            <Label
                              basic
                              style={{ width: '100%' }}
                              className="cav-label"
                            >
                              {folder.liq}
                            </Label>
                          </Form.Field>
                        </Form.Group>
                      )}
                      {folder.cav && (
                        <Form.Group widths={'equal'} inline>
                          <Form.Field style={{ padding: '0' }}>
                            <label>Assurance vie</label>

                            <Label
                              basic
                              style={{ width: '100%' }}
                              className="cav-label"
                            >
                              {folder.cav}
                            </Label>
                          </Form.Field>
                        </Form.Group>
                      )}
                      <Form.Group widths={'equal'}>
                        <Form.Field>
                          <label>Notaire(s)</label>
                          <Label
                            basic
                            style={{ width: '100%' }}
                            className="cav-label"
                          >
                            {folder.notary || '-'}
                          </Label>
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Card.Content>
                </Card>
              </Card.Group>
              <Card fluid>
                <Card.Content extra>
                  <Icon name="folder" />
                  <span className="folder-egg-details-title">Type:</span>
                  {types[folder.type || ''] || '-'}
                  <br />

                  <Icon name="folder" />
                  <span className="folder-egg-details-title">
                    Numéro de dossier:
                  </span>
                  {folder.code}
                  <br />

                  <Icon name="calendar alternate outline" />
                  <span className="folder-egg-details-title">
                    Date d'ouverture:
                  </span>
                  {moment(folder.createdAt).format('DD/MM/YYYY')}
                  <br />
                  <Icon name="calendar alternate outline" />
                  <span className="folder-egg-details-title">
                    Dernière mise à jour :
                  </span>
                  {moment(folder.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                  {CAN_EDIT_KEYDATES ? (
                    <div>
                      <Icon name="calendar alternate outline" />
                      <span className="folder-egg-details-title">
                        Dernière sync GX :
                      </span>
                      {!!folder.lastSyncGX
                        ? moment(folder.lastSyncGX).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )
                        : '--'}
                    </div>
                  ) : null}
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Tab panes={panes} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row className="folder-actions">
            {renderFolderActions(folder)}
          </Grid.Row>
        </Grid>
      </div>
    );
  };

  const menu = useAppSelector(getActive);
  if (menu !== menuItems.folders) return null;
  return renderRowDetails(cloneDeep(props.folder));
};
/**
 *
 * todo
 * ajouter QP dans listing héritiers
 * ajouter mail/phone...
 */
