import React, { useEffect, useState } from 'react';

import { Button, Divider, Form, Icon, Message, Modal } from 'semantic-ui-react';
import toasts from '../../../helpers/toast';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  createUser,
  isLoading,
  selectUserLists,
  updateUser,
} from './userSlice';
import { IUser } from './interface';
import { userTypeAdmin } from '../../../config/constants';

export const UserComponent = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const loading = useAppSelector(isLoading);
  const initialState: IUser = props.user || {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    notify_email: 'tgicquel@etudeguenifey.fr',
    password: '',
    access_mode: '',
    bank: '',
  };
  const [user, setUser] = useState(initialState);

  const users = useAppSelector(selectUserLists);

  interface dropDownELement {
    value: string;
    text: string;
  }
  const initialBanques = [] as dropDownELement[];
  const [banques, setBanques] = useState([...initialBanques]);

  useEffect(() => {
    setBanques(
      [
        ...new Set((users.userList?.data).map((user: any) => user.bank).sort()),
      ].map((b: any) => ({
        value: b,
        text: b,
      })),
    );
  }, [users]);

  const setField = (e: any, { name, value, required }: any) => {
    if (value && name === 'lastName') value = value.toUpperCase();
    setUser({ ...user, [name]: value });
  };
  const save = async () => {
    const required: any = [
      { name: "Type d'accès", value: user.access_mode },
      { name: 'Email', value: user.email },
      { name: 'Prénom', value: user.firstName },
      { name: 'Nom', value: user.lastName },
      { name: 'Expéditeur du mail AR', value: user.notify_email },
    ];
    if (user.access_mode !== userTypeAdmin) {
      required.push({ name: 'Banque', value: user.bank });
    }
    if (!user.id) {
      required.push({ name: 'Mot de passe', value: user.password });
    }

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!user.email.match(validRegex)) {
      toasts.error("L'adresse email est incorrecte");
      return;
    }

    const error = required.some((field: any) => {
      return toasts.checkValue(field.value, field.name);
    });
    if (!error) {
      if (user.id) {
        dispatch(
          updateUser(user, (data) => {
            if (data?.id) {
              setOpen(false);
              setUser(initialState);
            }
          }),
        );
      } else {
        dispatch(
          createUser(user, (data) => {
            if (data?.id) {
              setOpen(false);
              setUser(initialState);
            }
          }),
        );
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={props.trigger}
    >
      <Modal.Header>
        <Icon name="user" />{' '}
        {user.id
          ? user.firstName + ' ' + user.lastName
          : 'Créer un utilisateur'}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message floating>
            <Form onSubmit={save}>
              <Form.Group widths="equal">
                <Form.Select
                  label="Type d'accès"
                  placeholder="Type d'accès"
                  options={[
                    { key: 'banque', value: 'banque', text: 'Client' },
                    { key: 'admin', value: 'admin', text: 'Admin (EGG)' },
                  ]}
                  name="access_mode"
                  value={user.access_mode}
                  onChange={setField}
                  required
                />
                {user.access_mode === 'banque' ? (
                  <Form.Dropdown
                    options={banques}
                    placeholder="Client"
                    name="bank"
                    search
                    label={<label>Client</label>}
                    selection
                    fluid
                    allowAdditions
                    additionLabel="Ajouter "
                    noResultsMessage="Aucun resultat trouvé."
                    value={user.bank}
                    onAddItem={(e, { value }) => {
                      setBanques([
                        { text: value as string, value: value as string },
                        ...banques,
                      ]);
                    }}
                    onChange={setField}
                    required
                  />
                ) : (
                  <Form.Field></Form.Field>
                )}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Nom"
                  placeholder="Nom"
                  name="lastName"
                  value={user.lastName}
                  onChange={setField}
                  required
                />
                <Form.Input
                  label="Prénom"
                  placeholder="Prénom"
                  name="firstName"
                  value={user.firstName}
                  onChange={setField}
                  required
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Email"
                  placeholder="Email"
                  name="email"
                  value={user.email}
                  onChange={setField}
                  required
                />
                <Form.Input
                  label={
                    'Mot de passe' +
                    (user.id
                      ? ' : laissez ce champs vide pour ne pas le changer'
                      : '')
                  }
                  placeholder="Mot de passe"
                  name="password"
                  value={user.password}
                  onChange={setField}
                  required={!user.id}
                />
              </Form.Group>
              <Divider />
              <Form.Group widths="equal">
                <Form.Input
                  label="Expéditeur du mail AR"
                  placeholder="Expéditeur du mail AR"
                  defaultValue="tgicquel@etudeguenifey.fr"
                  name="notify_email"
                  value={user.notify_email}
                  onChange={setField}
                  required
                />
                <Form.Input
                  label="Durée de validité du mot de passe, en jours"
                  placeholder="Durée de validité du mot de passe, en jours"
                  name="passwordExpiryDays"
                  defaultValue="120"
                  value={user.passwordExpiryDays}
                  onChange={setField}
                  required
                />
              </Form.Group>
            </Form>
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          onClick={() => save()}
          primary
          icon
          labelPosition="right"
        >
          Enregistrer <Icon name="save" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
