import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { menuItems } from "../../../config/constants";

const initialState: any = { active: menuItems.folders };
export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    start: (state) => {
      return {
        ...state,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    error: (state, action: PayloadAction<string>) => {
      return {
        ...state,
      };
    },
  },
});
export const setActive = (active: any) => async (dispatch: any) => {
  dispatch(success({ active }));
};
export const { start, success, error } = menuSlice.actions;
export const getActive = (state: RootState) => {
  return state.menu.active;
};

export const menuReducer = menuSlice.reducer;
