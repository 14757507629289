import { isNew } from '../Common/utils';
import { IFolder } from './interface';

export const scrollTableTo = (folderId: string) => {
  // if (appBody) appBody.scrollTop = row.offsetTop;
  setTimeout(() => {
    const appBody = document.querySelector('#app-body') as HTMLElement;
    const dashboard = document.querySelector(
      '#folders-dashboard-container',
    ) as HTMLElement;
    const row = document.querySelector(
      '#details-row-folder-' + folderId,
    ) as HTMLElement;
    const mainRow = document.querySelector(
      '#main-row-folder-' + folderId,
    ) as HTMLElement;

    window.location.href = '#';
    window.location.href = '#main-row-folder-' + folderId;

    /*row?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });*/
  }, 500);

  return true;
};

export const getNew = (folder: IFolder) => {
  const haveNew = {
    document: false,
    message: false,
    heir: false,
  };
  (folder.documents || []).forEach((document) => {
    if (isNew(document.createdAt)) haveNew.document = true;
  });
  (folder.heirs || []).forEach((heir) => {
    if (isNew(heir.createdAt)) haveNew.heir = true;
  });
  (folder.messages || []).forEach((message) => {
    if (isNew(message.createdAt)) haveNew.message = true;
  });
  return haveNew;
};
