import React, { useState } from 'react';

import { Button, Divider, Form, Icon, Message, Modal } from 'semantic-ui-react';
import toasts from '../../../helpers/toast';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  createBank,
  isLoading,
  selectBankLists,
  updateBank,
} from './bankSlice';
import { IBank } from './interface';

export const BankComponent = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const loading = useAppSelector(isLoading);
  const initialState: IBank = props.Bank || {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    notify_email: 'tgicquel@etudeguenifey.fr',
    password: '',
    access_mode: '',
    bank: '',
  };
  const [Bank, setBank] = useState(initialState);

  const Banks = useAppSelector(selectBankLists);

  const initialBanques =
    [...new Set(Banks.BankList?.data.map((Bank: any) => Bank.bank).sort())] ||
    [];
  const [banques, setBanques] = useState(
    initialBanques.map((b) => ({ value: b, text: b })) as any,
  );

  const setField = (e: any, { name, value, required }: any) => {
    if (value && name === 'lastName') value = value.toUpperCase();
    setBank({ ...Bank, [name]: value });
  };
  const save = async () => {
    const required: any = [
      { name: "Type d'accès", value: Bank.access_mode },
      { name: 'Email', value: Bank.email },
      { name: 'Prénom', value: Bank.firstName },
      { name: 'Nom', value: Bank.lastName },
      { name: 'Expéditeur du mail AR', value: Bank.notify_email },
    ];

    if (!Bank.id) {
      required.push({ name: 'Mot de passe', value: Bank.password });
    }

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!Bank.email.match(validRegex)) {
      toasts.error("L'adresse email est incorrecte");
      return;
    }

    const error = required.some((field: any) => {
      return toasts.checkValue(field.value, field.name);
    });
    if (!error) {
      if (Bank.id) {
        dispatch(
          updateBank(Bank, (data) => {
            if (data?.id) {
              setOpen(false);
              setBank(initialState);
            }
          }),
        );
      } else {
        dispatch(
          createBank(Bank, (data) => {
            if (data?.id) {
              setOpen(false);
              setBank(initialState);
            }
          }),
        );
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      trigger={props.trigger}
    >
      <Modal.Header>
        <Icon name="cogs" />{' '}
        {Bank.id
          ? Bank.firstName + ' ' + Bank.lastName
          : 'Créer un utilisateur'}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message floating>
            <Form onSubmit={save}>
              <Form.Group widths="equal">
                <Form.Select
                  label="Type d'accès"
                  placeholder="Type d'accès"
                  options={[
                    { key: 'banque', value: 'banque', text: 'Banque' },
                    { key: 'admin', value: 'admin', text: 'Admin (EGG)' },
                  ]}
                  name="access_mode"
                  value={Bank.access_mode}
                  onChange={setField}
                  required
                />
                {Bank.access_mode === 'banque' ? (
                  <Form.Dropdown
                    options={banques}
                    placeholder="Banque"
                    name="bank"
                    search
                    label={<label>Banque</label>}
                    selection
                    fluid
                    allowAdditions
                    additionLabel="Ajouter "
                    noResultsMessage="Aucun resultat trouvé."
                    value={Bank.bank}
                    onAddItem={(e, { value }) => {
                      setBanques([{ text: value, value }, ...banques]);
                    }}
                    onChange={setField}
                    required
                  />
                ) : (
                  // <Form.Input
                  //   label="Banque"
                  //   placeholder="Banque"
                  //   name="bank"
                  //   value={Bank.bank}
                  //   onChange={setField}
                  //   required
                  // />
                  <Form.Field />
                )}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Nom"
                  placeholder="Nom"
                  name="lastName"
                  value={Bank.lastName}
                  onChange={setField}
                  required
                />
                <Form.Input
                  label="Prénom"
                  placeholder="Prénom"
                  name="firstName"
                  value={Bank.firstName}
                  onChange={setField}
                  required
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Email"
                  placeholder="Email"
                  name="email"
                  value={Bank.email}
                  onChange={setField}
                  required
                />
                <Form.Input
                  label={
                    'Mot de passe' +
                    (Bank.id
                      ? ' : laissez ce champs vide pour ne pas le changer'
                      : '')
                  }
                  placeholder="Mot de passe"
                  name="password"
                  value={Bank.password}
                  onChange={setField}
                  required={!Bank.id}
                />
              </Form.Group>
              <Divider />
              <Form.Group widths="equal">
                <Form.Select
                  label="Expéditeur du mail AR"
                  placeholder="Expéditeur du mail AR"
                  options={[
                    {
                      key: 'tgicquel@etudeguenifey.fr',
                      value: 'tgicquel@etudeguenifey.fr',
                      text: 'tgicquel@etudeguenifey.fr',
                    },
                  ]}
                  name="notify_email"
                  value={Bank.notify_email}
                  onChange={setField}
                  required
                />
                <Form.Field />
              </Form.Group>
            </Form>
          </Message>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          onClick={() => save()}
          primary
          icon
          labelPosition="right"
        >
          Enregistrer <Icon name="save" />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
