import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import userService from '../../services/userService';
import { RootState } from '../../store';
import { IpaginatedUsers, IUser, IUsersFilter } from './interface';
import { removeTokens } from '../../services/localStorage';

export interface IUsersList {
  isLoading: boolean;
  userList: IpaginatedUsers;
  selected?: IUser;
  filter?: IUsersFilter;
}
const initialState: IUsersList = {
  isLoading: false,
  userList: { data: [], total: 0 },
};
export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    start: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    update: (state, action: PayloadAction<any>) => {
      let data = state.userList?.data;
      if (state.userList) {
        data = [...state.userList.data].map((user) => {
          if (user.id === action.payload.selected.id)
            return action.payload.selected;
          return user;
        });
      }
      return {
        ...state,
        userList: { ...state.userList, data },
        ...action.payload,
        isLoading: false,
      };
    },
    error: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});
export const fetchUsers = (filter?: IUsersFilter) => async (dispatch: any) => {
  dispatch(start({ filter }));
  try {
    const userLists = await userService.getUserList(filter);
    dispatch(success({ userList: userLists }));
  } catch (err) {
    dispatch(error());
  }
};

export const createUser =
  (data: IUser, cb = (user: IUser) => {}) =>
  async (dispatch: any) => {
    dispatch(start({}));
    try {
      const user = await userService.createUser(data);
      if (user?.id) dispatch(success({ selected: user }));
      else dispatch(error());
      cb(user);
      // dispatch(fetchUsers());
    } catch (err) {
      dispatch(error());
    }
  };

export const updateUser =
  (data: IUser, cb = (user: IUser) => {}) =>
  async (dispatch: any) => {
    dispatch(start({}));
    try {
      const user = await userService.updateUser(data);
      if (user?.id) dispatch(update({ selected: user }));
      else dispatch(error());
      cb(user);
      // dispatch(fetchUsers());
    } catch (err) {
      dispatch(error());
    }
  };
export const { start, success, error, update } = userListSlice.actions;
export const selectUserLists = (state: RootState) => {
  return state.userList;
};

export const isLoading = (state: RootState) => {
  return state.userList.isLoading;
};

export const getFilter = (state: RootState) => {
  return state.userList.filter;
};

export const usersListReducer = userListSlice.reducer;
export const logout = (reload = false) => {
  removeTokens();
  if (reload) window.location.reload();
};
