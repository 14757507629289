import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPage, FormPage, ForgotPassword } from './app/components';
import { isAuthenticated } from './app/services/authenticationService';
import { getAccessMode } from './app/services/localStorage';
import { userTypeAdmin } from './config/constants';

import 'semantic-ui-css/semantic.min.css';
import './App.css';
import { AdminComponent } from './app/routes/admin';
function App() {
  const mode = getAccessMode();
  //const Landing = mode === userTypeAdmin ? AdminComponent : FormPage;
  return (
    <div className={'app-container ' + mode} id={'app-container'}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/reset-password/:user/:token"
            element={<ForgotPassword />}
          />

          <Route
            path="/"
            element={isAuthenticated() ? <AdminComponent /> : <LoginPage />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
