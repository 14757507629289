import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import folderService from '../../services/folderService';
import { RootState } from '../../store';
import {
  Document,
  FolderMessage,
  Heir,
  IFolder,
  IFoldersFilter,
  IpaginatedFolders,
  KeyDates,
} from './interface';
import { removeTokens } from '../../services/localStorage';
import { forceDownload } from '../../../helpers/common';
import { cloneDeep } from 'lodash';
import { isNew } from '../Common/utils';
import { toast } from 'react-toastify';
export interface IFoldersList {
  isLoading: boolean;
  folderList?: IpaginatedFolders;
  // filteredFolders?: IpaginatedFolders;

  selected?: IFolder;
  updated?: IFolder;
  filter?: IFoldersFilter;
}
const initialState: IFoldersList = { isLoading: false };
export const folderListSlice = createSlice({
  name: 'folderList',
  initialState,
  reducers: {
    start: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    },
    success: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    error: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const fetchFolders =
  (filter?: IFoldersFilter) => async (dispatch: any) => {
    dispatch(start({ filter }));
    try {
      const folderLists: IpaginatedFolders = await folderService.getFolderList(
        filter,
      );
      dispatch(success({ folderList: folderLists }));
    } catch (err) {
      dispatch(error(err as string));
    }
  };

export const setFilterFolders =
  (filter: any) => async (dispatch: any, getState: any) => {
    const state = getState() as RootState;
    try {
      dispatch(success({ filter: { ...getFilter(state), ...filter } }));
    } catch (err) {
      console.log(err);
      dispatch(error(err as string));
    }
  };

export const getDocument = (document: Document) => async (dispatch: any) => {
  try {
    const file = await folderService.getDocument(document);
    forceDownload(file, document.filename);
  } catch (err) {}
};

export const createFolder =
  (data: IFolder, cb = (folder: IFolder) => {}) =>
  async (dispatch: any) => {
    dispatch(start({}));
    try {
      const folder = await folderService.createFolder(data);
      dispatch(success({ selected: folder }));
      cb(folder);
    } catch (err) {
      dispatch(error(err as string));
    }
  };
export const updateFolder =
  (data: IFolder, cb = (_folder: IFolder) => {}) =>
  async (dispatch: any, getState: any) => {
    const state = cloneDeep(getState()).folderList;

    dispatch(start({}));
    try {
      const folder = await folderService.updateFolder(data);
      state.folderList.data = state.folderList.data.map((f: IFolder) => {
        if (f.id === folder.id) return folder;
        return f;
      });

      dispatch(success({ updated: folder, folderList: state.folderList }));
      cb(folder);
    } catch (err) {
      dispatch(error(err as string));
    }
  };

export const deleteFolder =
  (data: IFolder, cb = (_folder: IFolder) => {}) =>
  async (dispatch: any, getState: any) => {
    const state = cloneDeep(getState()).folderList;

    dispatch(start({}));
    try {
      await folderService.deleteFolder(data);
      state.folderList.data = state.folderList.data.filter(
        (f: IFolder) => f.id !== data.id,
      );

      dispatch(success({ updated: undefined, folderList: state.folderList }));
      cb(data);
    } catch (err) {
      dispatch(error(err as string));
    }
  };

const updateFolderInState = (state: any, folder: IFolder) => {
  state.folderList.data = ([...cloneDeep(state.folderList.data)] || []).map(
    (fo: IFolder) => {
      if (fo.id === folder.id) {
        return cloneDeep(folder);
      }
      return fo;
    },
  );
};
export const addDocuments =
  (folderId: string, documents: Document[], cb = (folder: IFolder) => {}) =>
  async (dispatch: any, getState: any) => {
    dispatch(start({}));
    const state = cloneDeep(getState()).folderList;
    try {
      const folder = await folderService.addDocuments(folderId, documents);
      updateFolderInState(state, folder);
      dispatch(success(state));
      dispatch(success({ selected: folder }));
      cb(folder);
    } catch (err) {
      dispatch(error(err as string));
    }
  };
export const addMessage =
  (folderId: string, message: FolderMessage, cb = (folder: IFolder) => {}) =>
  async (dispatch: any, getState: any) => {
    dispatch(start({}));
    const state = cloneDeep(getState()).folderList;
    try {
      const folder = await folderService.addMessage(folderId, message);
      updateFolderInState(state, folder);
      dispatch(success(state));
      dispatch(success({ selected: folder }));
      toast.success('Opération effectuée avec succès');
      cb(folder);
    } catch (err) {
      dispatch(error(err as string));
    }
  };

export const setFolderHeirActive =
  (
    folder: IFolder,
    heir: Heir,
    active: boolean,
    cb = (folder: IFolder) => {},
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch(start({}));
    const state = cloneDeep(getState()).folderList;
    try {
      const newFolder = await folderService.setFolderHeirActive(
        folder,
        heir,
        active,
      );
      updateFolderInState(state, newFolder);
      dispatch(success(state));
      dispatch(success({ selected: newFolder }));
      toast.success('Opération effectuée avec succès');
      cb(newFolder);
    } catch (err) {
      dispatch(error(err as string));
    }
  };
export const syncFolder =
  (folder: IFolder, cb = (folder: IFolder) => {}) =>
  async (dispatch: any, getState: any) => {
    dispatch(start({}));
    const state = cloneDeep(getState()).folderList;
    try {
      const newFolder = await folderService.syncGX(folder);
      updateFolderInState(state, newFolder);
      dispatch(success(state));
      dispatch(success({ selected: newFolder }));
      toast.success('Opération effectuée avec succès');
      cb(newFolder);
    } catch (err) {
      console.log(err);
      dispatch(error(err as string));
    }
  };

export const { start, success, error } = folderListSlice.actions;
export const selectFolderLists = (state: RootState) => {
  return state.folderList;
};

export const isLoading = (state: RootState) => {
  return state.folderList.isLoading;
};
export const getFolder = (state: RootState) => {
  return state.folderList.selected;
};

export const getFilter = (state: RootState) => {
  return state.folderList.filter;
};

export const initFolder = () => async (dispatch: any) => {
  dispatch(success({ selected: null }));
};
export const getFilteredFolders = (state: RootState, ignoreStatus = false) => {
  const filter = state.folderList.filter as IFoldersFilter;
  const folders: IFolder[] = state.folderList.folderList?.data || [];
  if (!filter || !Object.keys(filter).length) return folders;
  return folders.filter((folder: IFolder) => {
    const conditions = [];
    if (filter.bank) conditions.push(folder.user?.bank === filter.bank);
    if (!ignoreStatus && filter.status)
      conditions.push(folder.status === filter.status);

    if (filter.newDoc) {
      conditions.push(
        folder.documents.some((document) => isNew(document.createdAt)),
      );
    }

    if (filter.newNote) {
      conditions.push(
        folder.messages.some((document) => isNew(document.createdAt)),
      );
    }
    if (filter.newFolder) {
      conditions.push(isNew(folder.updatedAt));
    }
    if (filter.deceased) {
      conditions.push(
        (folder.lastName + ' ' + folder.firstName)
          .toLowerCase()
          .match(new RegExp(filter.deceased.toLowerCase(), 'i')) ||
          (folder.firstName + ' ' + folder.lastName)
            .toLowerCase()
            .match(new RegExp(filter.deceased.toLowerCase(), 'i')),
      );
    }
    if (filter.reference) {
      conditions.push(
        (folder.reference || '')
          .toLowerCase()
          .match(new RegExp(filter.reference.toLowerCase(), 'i')),
      );
    }
    if (filter.code) {
      conditions.push(
        (folder.code || '')
          .toLowerCase()
          .match(new RegExp(filter.code.toLowerCase(), 'i')),
      );
    }
    if (filter.type) {
      conditions.push(
        (folder.type || '')
          .toLowerCase()
          .match(new RegExp(filter.type.toLowerCase(), 'i')),
      );
    }

    return conditions.every(Boolean);
  });
};

export const foldersListReducer = folderListSlice.reducer;
export const logout = (reload = false) => {
  removeTokens();
  if (reload) window.location.reload();
};
