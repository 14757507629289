export const setTokens = (authRes: any) => {
  if (authRes?.access_token) {
    localStorage.setItem('token', authRes.access_token);
    localStorage.setItem('access_mode', authRes.access_mode);
    localStorage.setItem('user', JSON.stringify(authRes.user));
  }
};

export const removeTokens = () => {
  localStorage.clear();
};
export const getAccessToken = () => localStorage.getItem('token');
export const getAccessMode = () => localStorage.getItem('access_mode');
export const getUser = () => JSON.parse(localStorage.getItem('user') || '{}');
//export const setUser = (user: any) =>
//  localStorage.setItem("user", JSON.stringify(user));
//export const getRefreshToken = () => localStorage.getItem("refresh_token");
