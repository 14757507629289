import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Message,
  Segment,
  Header,
  Icon,
  Image,
  Label,
  Modal,
  Checkbox,
  Radio,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import toasts from '../../../helpers/toast';
import { Document, FolderType, IFolder } from '../Folders/interface';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  createFolder,
  isLoading,
  getFolder,
  initFolder,
} from '../Folders/folderSlice';
import { fetchUsers, selectUserLists } from '../Users/userSlice';
import { capitalizeFirstLetter } from '../../../helpers/common';
import { getUser } from '../../services/localStorage';
import { IUsersFilter } from '../Users/interface';
import moment from 'moment-timezone';
const user = getUser();
const IS_ADMIN = user.bank === '';
type formPageProps = {
  folder?: IFolder;
  onChange?: (folder: IFolder) => void;
};
export function FormPage(props: formPageProps) {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(isLoading);
  const selected = useAppSelector(getFolder);
  const initialState: IFolder = {
    bank: user.bank,
    id: '',
    idGX: '',
    reference: '',
    lastName: '',
    firstName: '',
    birthdate: null,
    deathdate: null,
    cav: '',
    liq: '',
    comments: '',
    documents: [],
    heirs: [],
    messages: [],
    keyDates: { ENQ: null, VFR: null, PRD: null, DRN: null, DONE: null },
  };
  const [folder, setFolder] = useState(props.folder || initialState);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const otherfilesInputRef = useRef<HTMLInputElement>(null);
  const fileChange = (event: any) => {
    event.stopPropagation();
    setFolder({
      ...folder,
      certificate: event.target.files,
    });
  };
  const otherFileChange = (event: any) => {
    event.stopPropagation();
    setFolder({
      ...folder,
      documents: event.target.files,
    });
  };
  const setField = (e: any, { name, value, required }: any) => {
    if (value && name === 'lastName') value = value.toUpperCase();
    if (value && name === 'firstName') value = capitalizeFirstLetter(value);
    if (['cdc', 'domaines'].includes(name)) {
      value = !!value;
    }
    const updatedFolder = { ...folder, [name]: value };
    setFolder(updatedFolder);
    props.onChange && props.onChange(updatedFolder);
  };
  const save = (e: any, data: any) => {
    const error = [
      { name: 'Nom', value: folder.lastName },
      { name: 'Prénom', value: folder.firstName },
      { name: 'Date de décès', value: folder.deathdate },
    ].some((field: any) => {
      return toasts.checkValue(field.value, field.name);
    });
    if (!error) {
      dispatch(
        createFolder(folder, () => {
          //disable save + modal message
        }),
      );
    }
  };
  const users = useAppSelector(selectUserLists);

  useEffect(() => {
    if (!users.userList?.total) dispatch(fetchUsers({} as IUsersFilter));
  }, [dispatch]);
  const initialBanques =
    [...new Set(users.userList?.data.map((user) => user.bank).sort())] || [];
  if (!initialBanques.length) {
    initialBanques.push(user.bank);
  }
  const [banques, setBanques] = useState(
    initialBanques.map((b) => ({ value: b, text: b })) as any,
  );
  return (
    <div className="submit-folder-form">
      <Form onSubmit={save}>
        <Form.Group widths="equal">
          <Form.Input
            label="Référence"
            placeholder="Référence"
            name="reference"
            value={folder.reference}
            onChange={setField}
          />
          <Form.Dropdown
            disabled={!IS_ADMIN}
            options={banques}
            placeholder="Client"
            name="bank"
            search
            label={<label>Client</label>}
            selection
            fluid
            allowAdditions
            additionLabel="Ajouter "
            noResultsMessage="Aucun resultat trouvé."
            value={folder?.user?.bank || folder.bank}
            onAddItem={(e, { value }) => {
              setBanques([{ text: value, value }, ...banques]);
            }}
            onChange={setField}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Nom"
            placeholder="Nom"
            name="lastName"
            value={folder.lastName}
            onChange={setField}
            required
          />
          <Form.Input
            label="Prénom"
            placeholder="Prénom"
            name="firstName"
            value={folder.firstName}
            onChange={setField}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <SemanticDatepicker
            label="Date de naissance"
            locale="fr-FR"
            format="DD/MM/YYYY"
            name="birthdate"
            value={folder.birthdate && moment(folder.birthdate).toDate()}
            onChange={setField}
          />
          <SemanticDatepicker
            label="Date de décès"
            locale="fr-FR"
            format="DD/MM/YYYY"
            name="deathdate"
            value={folder.deathdate && moment(folder.deathdate).toDate()}
            onChange={setField}
            required
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Montant des avoirs bancaires</label>{' '}
            <Input
              label={{ basic: true, content: '€' }}
              labelPosition="right"
              placeholder="Uniquement en Euro"
              name="liq"
              value={folder.liq}
              type="number"
              onChange={setField}
            />
          </Form.Field>
          <Form.Field>
            <label>Versé à la CDC</label>
            <Radio
              name="cdc"
              label="Oui"
              value={1}
              checked={!!folder.cdc}
              onChange={setField}
            />{' '}
            <Radio
              name="cdc"
              label="Non"
              value={0}
              checked={!folder.cdc}
              onChange={setField}
            />
          </Form.Field>
          <Form.Field>
            <label>Versé au Domaine</label>
            <Radio
              name="domaines"
              label="Oui"
              value={1}
              checked={!!folder.domaines}
              onChange={setField}
            />{' '}
            <Radio
              name="domaines"
              label="Non"
              value={0}
              checked={!folder.domaines}
              onChange={setField}
            />
          </Form.Field>
        </Form.Group>
        <Form.TextArea
          label="Contrat(s) d’assurance-vie"
          placeholder="Veuillez préciser le montant du capital et les clauses bénéficiaires"
          name="cav"
          value={folder.cav}
          onChange={setField}
        />

        <Form.TextArea
          label="Commentaires"
          placeholder="Commentaires"
          name="comments"
          value={folder.comments}
          onChange={setField}
        />
        {!folder?.id ? (
          <Form.Group inline>
            {' '}
            <label>Possédez vous l’acte de décès du défunt ?</label>
            <Form.Radio
              name="acte"
              checked={folder.acte === '1'}
              label="Oui"
              value="1"
              onChange={setField}
            />
            <Form.Radio
              name="acte"
              checked={folder.acte !== '1'}
              label="Non"
              value="0"
              onChange={setField}
            />
          </Form.Group>
        ) : null}
        {folder.acte === '1' && !folder?.id ? (
          <Form.Group widths="equal">
            <Form.Field>
              <Button
                content="Parcourir l'acte de décès"
                labelPosition="left"
                icon="file pdf outline"
                onClick={(e) => {
                  e.preventDefault();
                  fileInputRef.current?.click();
                }}
                fluid
              />
              <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={fileChange}
                accept="application/pdf"
              />
            </Form.Field>

            <Form.Field className="files-container">
              {Array.from(folder.certificate || [])
                // .filter((doc) => doc.fieldname === 'certificate')
                .map((doc, i) =>
                  doc?.name ? (
                    <Label tag key={i}>
                      <Icon name="file pdf outline" />
                      {doc.name}
                    </Label>
                  ) : null,
                )}
            </Form.Field>
          </Form.Group>
        ) : null}
        {folder?.id ? null : (
          <Form.Group widths="equal">
            <Form.Field>
              <Button
                content={
                  'Parcourir ' +
                  (folder.acte === '1' ? "d'autres" : 'des') +
                  ' documents'
                }
                labelPosition="left"
                icon="file pdf outline"
                onClick={(e) => {
                  e.preventDefault();
                  otherfilesInputRef.current?.click();
                }}
                fluid
              />
              <input
                ref={otherfilesInputRef}
                type="file"
                hidden
                multiple
                onChange={otherFileChange}
                accept="application/pdf"
              />
            </Form.Field>

            <Form.Field className="files-container">
              {Array.from(folder.documents || [])
                //  .filter((doc) => doc.fieldname !== 'certificate')
                .map((doc: any, i) => (
                  <Label tag key={i}>
                    <Icon name="file pdf outline" />
                    {doc.name}
                  </Label>
                ))}
            </Form.Field>
          </Form.Group>
        )}
        {folder?.id ? null : (
          <div className="centered">
            <Button
              disabled={!!selected}
              loading={loading}
              type="submit"
              icon
              labelPosition="right"
              fluid
              primary
            >
              Envoyer
              <Icon name="send" />
            </Button>
          </div>
        )}
      </Form>
      <Modal
        dimmer={'blurring'}
        open={!!selected && !!selected.id}
        closeOnDimmerClick={true}
        onClose={() => dispatch(initFolder())}
      >
        <Modal.Header>
          <Icon name="check" /> Votre dossier a bien été transmis
        </Modal.Header>
        <Modal.Content>
          Un accusé de réception vous a été envoyé par mail.
        </Modal.Content>
      </Modal>
    </div>
  );
}
